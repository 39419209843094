import axios from "helpers/axios";
import { backendURL } from "config";
import {
  IBulkUploadedRequest,
  ICategory,
  IProductLocale,
  ISubsCategory,
  ITax,
} from "interfaces";
import axiosFormData from "helpers/axiosFormData";

export async function createNewTenant(tenant: {
  companyName: string;
  companyLegalName: string;
  domain: string;
  receivingEmail: string;
  supportEmail: string;
  sendingEmail: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  users: Array<string>;
}) {
  const URL = backendURL + "/tenants";

  const res = axios
    .post(
      URL,
      JSON.stringify({
        ...tenant,
      })
    )
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function exchangeCurrency(
  fromCurrency: string,
  toCurrency: string,
  amount: number
) {
  const URL = backendURL + "/exchange";

  const res = axios
    .post(URL, {
      fromC: fromCurrency,
      toC: toCurrency,
      amount: amount,
    })
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getCategoriesByTenant(tenantId: string) {
  const URL = backendURL + "/categories?tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getCategoryLocales(categoryId: string) {
  const URL = backendURL + "/categories/" + categoryId + "/category_locales";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getParentCategoriesByTenant(tenantId: string) {
  const URL =
    backendURL + "/categories?tenant.id=" + tenantId + "&exists[parent]=false";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createCategory(
  name: string,
  parentId: string,
  tenantId: string,
  isVisible: boolean,
  image: any
) {
  const URL = backendURL + "/categories";

  const res = axios
    .post(URL, {
      name: name,
      tenant: "/tenants/" + tenantId,
      parent: parentId ? parentId : null,
      isVisible: isVisible,
      image: image,
    })
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateCategory(category: ICategory) {
  const URL = backendURL + "/categories/" + category.id;
  const res = axios
    .put(URL, category)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createCategoryLocale(category: any) {
  const URL = backendURL + "/category_locales";
  const res = axios
    .post(URL, category)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateCategoryLocale(category: any) {
  const URL = backendURL + "/category_locales/" + category.id;
  const res = axios
    .patch(URL, category)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deleteCategory(category: ICategory) {
  const URL = backendURL + "/categories/" + category.id;
  const res = axios
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getProductsByTax(tax: ITax) {
  const URL = backendURL + "/products?tax.id=" + tax.id;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getProductsByTenant(
  tenantId: string,
  page = 1,
  itemsPerPage = 10,
  orderBy = "id",
  order = "desc",
  categoryFilter = "",
  draftFilter = ""
) {
  let filterQuery =
    categoryFilter !== "" ? "&subcategory.id=" + categoryFilter : "";
  filterQuery += draftFilter !== "" ? "&draft=" + draftFilter : "";
  const URL =
    backendURL +
    "/products?tenant.id=" +
    tenantId +
    "&page=" +
    page +
    "&itemsPerPage=" +
    itemsPerPage +
    "&order[" +
    orderBy +
    "]=" +
    order +
    filterQuery;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getProductsByCategory(
  tenantId: string,
  categoryId: number
) {
  const URL =
    backendURL +
    "/products?tenant.id=" +
    tenantId +
    "&subcategory.id=" +
    categoryId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getProduct(productId: string) {
  const URL = backendURL + "/products/" + productId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getProductLocales(productId: string) {
  const URL = backendURL + "/products/" + productId + "/product_locales";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function searchProductsNames(tenantId: string, name: "") {
  const URL =
    backendURL +
    "/tenants/" +
    tenantId +
    "/productsSearch" +
    (name ? "?name=" + name : "");
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function searchProducts(name: string, tenantId: string) {
  const URL = backendURL + "/products?tenant.id=" + tenantId + "&name=" + name;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createProduct(product: any) {
  const URL = backendURL + "/products";

  const res = axios
    .post(URL, product)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createProductLocale(productLocale: IProductLocale) {
  const URL = backendURL + "/product_locales";

  const res = axios
    .post(URL, productLocale)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateProductLocale(productLocale: IProductLocale) {
  const URL = backendURL + "/product_locales/" + productLocale.id;

  const res = axios
    .patch(URL, productLocale)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function bulkUploadProducts(formData: FormData) {
  const URL = backendURL + "/bulk_upload";

  const res = axiosFormData
    .post(URL, formData)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getBulkUploadProductsByTenant(
  tenantId: string,
  page = 1,
  itemsPerPage = 10,
  orderBy = "id",
  order = "desc"
) {
  const URL =
    backendURL +
    "/bulk_upload_requests?tenant.id=" +
    tenantId +
    "&page=" +
    page +
    "&itemsPerPage=" +
    itemsPerPage +
    "&order[" +
    orderBy +
    "]=" +
    order;

  const res = axiosFormData
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function patchBulkUploadProducts(
  bulkUpload: IBulkUploadedRequest
) {
  const URL = backendURL + "/bulk_upload_requests/" + bulkUpload.id;
  const res = axios
    .patch(URL, bulkUpload)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getBulkUploadProducts(id: string) {
  const URL = backendURL + "/bulk_upload_requests/" + id;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function exportProductsByTenant(tenantId: string) {
  const URL =
    backendURL + "/productsExport?tenant.id=" + tenantId + "&pagination=false";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deleteProduct(product: any) {
  const URL = backendURL + "/products/" + product.id;

  const res = axios
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function editProduct(product: any) {
  const resourceId = product["@id"].split("/").slice(-1)[0];
  const URL = backendURL + "/products/" + resourceId;

  const res = axios
    .put(URL, product)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getSubscriptionsByTenant(
  tenantId: string,
  page = 1,
  itemsPerPage = 10,
  orderBy = "id",
  order = "desc"
) {
  const URL =
    backendURL +
    "/subscriptions?tenant.id=" +
    tenantId +
    "&page=" +
    page +
    "&itemsPerPage=" +
    itemsPerPage +
    "&order[" +
    orderBy +
    "]=" +
    order;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getSubscription(subscriptionId: string) {
  const URL = backendURL + "/subscriptions/" + subscriptionId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function searchSubscriptions(name: string, tenantId: string) {
  const URL =
    backendURL + "/subscriptions?tenant.id=" + tenantId + "&name=" + name;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createSubscription(subscription: any) {
  const URL = backendURL + "/subscriptions";

  const res = axios
    .post(URL, subscription)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deleteSubscription(subscription: any) {
  const URL = backendURL + "/subscriptions/" + subscription.id;
  const res = axios
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function editSubscription(subscription: any) {
  const URL = backendURL + "/subscriptions/" + subscription.id;

  const res = axios
    .put(URL, subscription)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getSubsCategoriesByTenant(tenantId: string) {
  const URL = backendURL + "/subs_categories?tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createSubsCategory(name: string, tenantId: string) {
  const URL = backendURL + "/subs_categories";

  const res = axios
    .post(URL, {
      name: name,
      tenant: "/tenants/" + tenantId,
    })
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateSubsCategory(category: ISubsCategory) {
  const URL = backendURL + "/subs_categories/" + category.id;
  const res = axios
    .patch(URL, category)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deleteSubsCategory(category: ISubsCategory) {
  const URL = backendURL + "/subs_categories/" + category.id;
  const res = axios
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}
