import useTranslation from "components/customHooks/translations";
import * as Yup from "yup";
import { Formik } from "formik";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { useUserContext } from "components/contexts/UserContext";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  MenuItem,
  Stack,
  Paper,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage, generateRandomString } from "helpers";
import SwitchField from "components/Form/SwitchField/SwitchField";
import FormNumberField from "components/Form/FormNumberField";
import {
  createAddress,
  createCoupon,
  createCustomer,
  updateCoupon,
} from "services/tenants";
import NumberPercentageField from "components/Form/NumberPercentageField";
import DateTimePickerField from "components/Form/DateTimePickerField";
import InfoPopover from "components/InfoPopover";
import { ICoupon, ICustomer } from "interfaces";
import { useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import PageHeader from "components/PageHeader";
import AutocompleteCountryField from "components/Form/AutocompleteCountryField";
import countrylist from "helpers/countryList";

const CustomersForm = ({ couponToEdit }: { couponToEdit?: ICoupon }) => {
  const { selectedTenant, tenants } = useUserContext();
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const navigate = useNavigate();

  const translation = useTranslation();

  const couponSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
    lastName: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
    email: Yup.string().email().required(translation.required),
    address: Yup.object().shape({
      name: Yup.string()
        .min(2, translation.signUpShortError)
        .max(50, translation.signUpLongError)
        .required(translation.required),
      streetAddress: Yup.string()
        .min(2, translation.signUpShortError)
        .max(255, translation.signUpLongError)
        .required(translation.required),
      city: Yup.string()
        .min(2, translation.signUpShortError)
        .max(255, translation.signUpLongError)
        .required(translation.required),
      county: Yup.string()
        .min(2, translation.signUpShortError)
        .max(63, translation.signUpLongError)
        .required(translation.required),
      country: Yup.string()
        .min(2, translation.signUpShortError)
        .max(63, translation.signUpLongError)
        .required(translation.required),
      phoneNumber: Yup.string()
        .min(2, translation.signUpShortError)
        .max(15, translation.signUpLongError)
        .required(translation.required),
      label: Yup.string()
        .min(2, translation.signUpShortError)
        .max(63, translation.signUpLongError)
        .required(translation.required),
    }),
  });

  async function submitForm(
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
    goBack = false
  ) {
    setSubmitting(true);

    try {
      const res = await createCustomer(tenants[selectedTenant].id, {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        email: values.email,
      });

      const res2 = await createAddress({
        name: values.address.name,
        streetAddress: values.address.streetAddress,
        city: values.address.city,
        county: values.address.county,
        country: values.address.country,
        phoneNumber: values.address.phoneNumber,
        customer: res.data.id,
        label: values.address.label,
        additionalInfo: values.address.additionalInfo,
        postalCode: values.address.postalCode,
        companyName: values.address.companyName,
        vatId: values.address.vatId,
        fiscalId: values.address.fiscalId,
      });

      setSuccessMessage(translation.savedMessage);

      setSubmitting(false);
      if (goBack) {
        navigate(routeNames.customers);
      }
    } catch (error) {
      setErrorMessage(errorMessage(error));
      setSubmitting(false);
    }
  }

  return (
    <>
      <PageHeader>{translation.newCustomerTitle}</PageHeader>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          phoneNumber: "",
          email: "",
          address: {
            name: "",
            streetAddress: "",
            city: "",
            county: "",
            country: "",
            phoneNumber: "",
            label: "Office",
            additionalInfo: "",
            postalCode: "",
            companyName: "",
            vatId: "",
            fiscalId: "",
          },
        }}
        validationSchema={couponSchema}
        enableReinitialize
        onSubmit={() => {
          console.log("submited");
        }}
      >
        {({
          values,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setSubmitting,
          resetForm,
        }) => {
          return (
            <>
              <form noValidate className="" onSubmit={handleSubmit}>
                <Paper sx={{ p: 2 }}>
                  <Stack spacing={2}>
                    <Typography variant="h5">
                      {translation.customers.customer}
                    </Typography>
                    <Stack direction={"row"} spacing={2}>
                      <Box>
                        <FormTextField
                          label={translation.customers.firstNameLabel}
                          name="firstName"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.customers.lastNameLabel}
                          name="lastName"
                        />
                      </Box>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Box>
                        <FormTextField
                          label={translation.customers.emailLabel}
                          name="email"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.customers.phoneNumberLabel}
                          name="phoneNumber"
                        />
                      </Box>
                    </Stack>
                    <Box sx={{ py: 2 }}>
                      <Divider />
                    </Box>
                    <Typography variant="h5">
                      {translation.customers.address}
                    </Typography>
                    <Box>
                      <FormTextField
                        label={translation.customers.labelLabel}
                        name="address.label"
                      />
                    </Box>
                    <Stack direction={"row"} spacing={2}>
                      <Box>
                        <FormTextField
                          label={translation.customers.addressNameLabel}
                          name="address.name"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.customers.contactPhoneNumberLabel}
                          name="address.phoneNumber"
                        />
                      </Box>
                    </Stack>
                    <Box>
                      <FormTextField
                        label={translation.customers.companyNameLabel}
                        name="address.companyName"
                      />
                    </Box>
                    <Stack direction={"row"} spacing={2}>
                      <Box>
                        <FormTextField
                          label={translation.customers.vatIdLabel}
                          name="address.vatId"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.customers.fiscalIdLabel}
                          name="address.fiscalId"
                        />
                      </Box>
                    </Stack>
                    <Box>
                      <FormTextField
                        label={translation.customers.streetAddressLabel}
                        name="address.streetAddress"
                      />
                    </Box>
                    <Stack direction={"row"} spacing={2}>
                      <Box>
                        <FormTextField
                          label={translation.customers.postalCodeLabel}
                          name="address.postalCode"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.customers.countyLabel}
                          name="address.county"
                        />
                      </Box>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Box>
                        <FormTextField
                          label={translation.customers.cityLabel}
                          name="address.city"
                        />
                      </Box>
                      <Box>
                        <AutocompleteCountryField
                          controlId="country01"
                          label={translation.customers.countryLabel}
                          type="text"
                          name="address.country"
                          options={countrylist}
                        />
                      </Box>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <LoadingButton
                        variant="contained"
                        type="submit"
                        //loading={isSubmitting}
                        onClick={() => {
                          submitForm(values, setSubmitting, true);
                        }}
                      >
                        {translation.saveAndBackButton}
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Paper>
              </form>
            </>
          );
        }}
      </Formik>{" "}
    </>
  );
};

export default CustomersForm;
