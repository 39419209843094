import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { routeNames } from "routes";
import userImage from "assets/img/placeholder.png";
import { logout } from "services/users";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { getUserById } from "services/tenants";
import MuiLink from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useLocation } from "react-router-dom";
import Logout from "@mui/icons-material/Logout";
import {
  ArrowDropDown,
  Person,
  SettingsInputCompositeOutlined,
  ShoppingBagOutlined,
} from "@mui/icons-material";
import { Divider, Stack } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import { drawerWidth } from "../SideNav";
import SelectTenant from "components/SelectTenant";
import CreateMenu from "components/Menu/CreateMenu";
import SettingsIcon from "@mui/icons-material/Settings";
import { routes } from "routes";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage } from "helpers";

const Navbar = () => {
  const { isLoading, userId, updateTrigger } = useUserContext();
  const [userData, setUserData] = useState<any>(null);
  const translation = useTranslation();
  const [breadcrumbs, setBreadcrumbs] = useState<any>([]);

  const { setErrorMessage } = useSnackbarContext();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const location = useLocation();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logout().then(() => (window.location.href = routeNames.home));
  };

  useEffect(() => {
    const pathname = location.pathname.split("/");
    let path = "";
    const newBreadcrumbs: any = [];
    pathname.forEach((item) => {
      if (item == "") {
        return;
      }
      path += "/" + item;
      routes.forEach((route) => {
        if (route.path.split("/:")[0] === path) {
          newBreadcrumbs.push({
            path: path,
            name: translation[
              route.titleVariable as keyof typeof translation
            ] as string,
          });
        }
      });
    });
    setBreadcrumbs(newBreadcrumbs);
  }, [location]);

  useEffect(() => {
    if (!isLoading) {
      getUserById(userId)
        .then((user) => {
          if (user.data) {
            setUserData(user.data);
          }
        })
        .catch((e) => setErrorMessage(errorMessage(e)));
    }
  }, [isLoading, updateTrigger, userId]);

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
      }}
      elevation={1}
    >
      <Toolbar disableGutters sx={{ marginX: 4 }}>
        <Stack direction={"row"} spacing={2}>
          {breadcrumbs.map((breadcrumb: any, index: number) => {
            return (
              <React.Fragment key={"breadcrumb-" + index}>
                {index !== 0 && (
                  <Typography
                    sx={{
                      color: "text.secondary",
                    }}
                  >
                    {">"}
                  </Typography>
                )}
                {index !== breadcrumbs.length - 1 ? (
                  <MuiLink underline="none" href={breadcrumb.path}>
                    <Typography>{breadcrumb.name}</Typography>
                  </MuiLink>
                ) : (
                  <Typography
                    sx={{
                      color: "text.secondary",
                    }}
                  >
                    {breadcrumb.name}
                  </Typography>
                )}
              </React.Fragment>
            );
          })}
        </Stack>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
          }}
        ></Box>
        <Box sx={{ flexGrow: 0, marginX: 3 }}>
          <SelectTenant />
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <CreateMenu />
        </Box>

        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ marginX: 3 }}
        />
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <Stack direction="row" alignItems={"center"}>
              <IconButton
                onClick={handleOpenUserMenu}
                disableRipple
                sx={{ p: 0 }}
              >
                <Avatar
                  variant="rounded"
                  src={
                    userData
                      ? userData.photo
                        ? userData.photo.contentUrl
                        : userImage
                      : userImage
                  }
                />
                <ArrowDropDown color="primary" />
              </IconButton>
            </Stack>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem disabled>
              <Typography variant="body1" gutterBottom>
                {userData
                  ? userData.firstName + " " + userData.lastName
                  : translation.loading}
              </Typography>
            </MenuItem>
            <MenuItem disabled>
              <Typography variant="body2" gutterBottom>
                {userData ? userData.email : translation.loading}
              </Typography>
            </MenuItem>
            <Divider></Divider>
            <MenuItem
              component={Link}
              to={routeNames.myProfile}
              onClick={handleCloseUserMenu}
            >
              <ListItemIcon>
                <Person fontSize="small" />
              </ListItemIcon>
              {translation.myProfile}
            </MenuItem>
            <MenuItem
              component={Link}
              to={routeNames.companyInfo}
              onClick={handleCloseUserMenu}
            >
              <ListItemIcon>
                <SettingsInputCompositeOutlined fontSize="small" />
              </ListItemIcon>
              {translation.companyInfoTitle}
            </MenuItem>
            <MenuItem
              component={Link}
              to={routeNames.policies}
              onClick={handleCloseUserMenu}
            >
              <ListItemIcon>
                <ShoppingBagOutlined fontSize="small" />
              </ListItemIcon>
              {translation.ecommerceMenu.ecommerceSettings}
            </MenuItem>
            <MenuItem
              component={Link}
              to={routeNames.applicationSettings}
              onClick={handleCloseUserMenu}
            >
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              {translation.technicalMenu.technicalSettings}
            </MenuItem>
            <Divider></Divider>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {translation.signOut}
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
