import React, { useEffect, useState } from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import useTranslation from "components/customHooks/translations";
import { LoadingButton } from "@mui/lab";
import { updateTax } from "services/tenants";
import { ITax } from "interfaces";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { getProductsByTax } from "services/products";
import { errorMessage } from "helpers";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        max={100}
        min={0}
        isAllowed={(values) => {
          const { floatValue } = values;
          if (floatValue) return floatValue <= 100;
          else return true;
        }}
        allowNegative={false}
        decimalScale={2}
        valueIsNumericString
        suffix="%"
      />
    );
  }
);

const ArrayNumberPercentageField = ({
  label,
  name,
  setIsEditingTax,
  taxes,
  resetForm,
  setUpdateTrigger,
}: {
  label?: string;
  name: string;
  setIsEditingTax: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateTrigger: any;
  taxes: ITax[];
  resetForm: any;
}) => {
  const [field, meta, helper] = useField(name);
  const [editIndex, setEditIndex] = useState<null | number>(null);
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const [deactivatingTax, setDeactivatingTax] = useState<null | ITax>(null);
  const [updateProductsPrices, setUpdateProductsPrices] = useState(false);
  const [newTaxAfterDeactivate, setNewTaxAfterDeactivate] = useState("");
  const [deactivateError, setDeactivateError] = useState(false);
  const [showUpdateProductsModal, setShowUpdateProductsModal] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const isInvalid = !!meta.error;
  const translation = useTranslation();

  useEffect(() => {
    setIsEditingTax(editIndex === null ? false : true);
  }, [editIndex, setIsEditingTax]);

  async function sendUpdateTax(updateProducts: boolean) {
    if (editIndex !== null) {
      const oldTax = taxes.find((tax) => {
        return tax.id === field.value[editIndex].id;
      });
      const newTax = {
        ...field.value[editIndex],
        updateProductsPrice: updateProducts,
        taxPercentage: parseFloat(field.value[editIndex].taxPercentage),
        oldTaxPercentage: oldTax?.taxPercentage,
      };
      updateTax(newTax)
        .then(() => {
          setSuccessMessage(translation.savedMessage);
          setUpdateTrigger((updateTrigger: boolean) => !updateTrigger);
          setEditIndex(null);
          setLoadingUpdate(false);
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setLoadingUpdate(false);
        });
    }
  }

  async function editTax() {
    setLoadingUpdate(true);
    if (editIndex !== null) {
      const oldTax = taxes.find((tax) => {
        return tax.id === field.value[editIndex].id;
      });
      if (field.value[editIndex].taxPercentage !== "") {
        const newTaxPercentage = parseFloat(
          field.value[editIndex].taxPercentage
        );
        if (newTaxPercentage !== oldTax?.taxPercentage) {
          const res = await getProductsByTax(field.value[editIndex]);
          if (res.data["hydra:totalItems"] > 0) {
            setShowUpdateProductsModal(true);
          } else {
            sendUpdateTax(false);
          }
        } else {
          sendUpdateTax(false);
        }
      } else {
        setErrorMessage(translation.taxes.taxPercentageRequired);
        setLoadingUpdate(false);
      }
    }
  }

  async function handleDeactivateTax(tax: ITax) {
    setLoadingUpdate(true);
    setDeactivatingTax(tax);
    const res = await getProductsByTax(tax);
    if (res.data["hydra:totalItems"] > 0) {
      setShowDeactivate(true);
    } else {
      updateTax({ ...tax, isActive: false })
        .then(() => {
          setSuccessMessage(translation.deletedMessage);
          setLoadingUpdate(false);
          setDeactivatingTax(null);
          setUpdateTrigger((updateTrigger: boolean) => !updateTrigger);
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setDeactivatingTax(null);
          setLoadingUpdate(false);
        });
    }
  }

  return (
    <>
      <Stack spacing={2}>
        <Typography>{label}</Typography>
        {field.value.map((value: any, index: number) => {
          return (
            <Stack
              key={"name-" + index}
              direction={"row"}
              alignItems={"flex-end"}
              spacing={4}
            >
              <TextField
                variant="standard"
                value={value.label}
                label={translation.taxes.labelLabel}
                disabled={
                  (field.value[index].id !== undefined &&
                    editIndex !== index) ||
                  (editIndex !== null && editIndex !== index)
                }
                onChange={(e) => {
                  const oldValues = [...field.value];
                  oldValues[index] = {
                    ...oldValues[index],
                    label: e.target.value,
                  };
                  if (index === oldValues.length - 1) {
                    oldValues.push({
                      label: "",
                      taxPercentage: "",
                      isActive: true,
                    });
                  }
                  if (
                    e.target.value === "" &&
                    oldValues[index].taxPercentage === "" &&
                    oldValues[index].id === undefined
                  ) {
                    oldValues.splice(index, 1);
                  }
                  helper.setValue(oldValues);
                }}
              />
              <TextField
                variant="standard"
                value={value.taxPercentage}
                placeholder="%"
                error={isInvalid && index !== field.value.length - 1}
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                }}
                sx={{ width: 60 }}
                disabled={
                  (field.value[index].id !== undefined &&
                    editIndex !== index) ||
                  (editIndex !== null && editIndex !== index)
                }
                onChange={(e) => {
                  const oldValues = [...field.value];
                  oldValues[index] = {
                    ...oldValues[index],
                    taxPercentage: e.target.value,
                  };
                  if (index === oldValues.length - 1) {
                    oldValues.push({
                      label: "",
                      taxPercentage: "",
                      isActive: true,
                    });
                  }
                  if (
                    e.target.value === "" &&
                    oldValues[index].label === "" &&
                    oldValues[index].id === undefined
                  ) {
                    oldValues.splice(index, 1);
                  }
                  helper.setValue(oldValues);
                }}
              />
              {index !== field.value.length - 1 &&
                field.value[index].id !== undefined &&
                editIndex === null && (
                  <Stack direction="row" spacing={1}>
                    <IconButton
                      disabled={loadingUpdate}
                      onClick={() => {
                        setEditIndex(index);
                      }}
                    >
                      <EditOutlined />
                    </IconButton>

                    {taxes.length > 1 && (
                      <IconButton
                        disabled={loadingUpdate}
                        color={"warning"}
                        onClick={() => {
                          handleDeactivateTax(field.value[index]);
                        }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    )}
                  </Stack>
                )}
              {editIndex === index && (
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setEditIndex(null);
                      resetForm();
                    }}
                  >
                    {translation.cancelButton}
                  </Button>
                  <LoadingButton
                    onClick={() => editTax()}
                    variant="contained"
                    loading={loadingUpdate}
                  >
                    {translation.saveButton}
                  </LoadingButton>
                </Stack>
              )}
            </Stack>
          );
        })}
        {loadingUpdate && <CircularProgress />}
      </Stack>
      <Dialog
        open={showUpdateProductsModal}
        onClose={() => setShowUpdateProductsModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translation.taxes.updateProductsPriceTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translation.taxes.updateProductsPriceDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            onClick={() => {
              setShowUpdateProductsModal(false);
              sendUpdateTax(false);
            }}
          >
            {translation.no}
          </Button>
          <Button
            variant={"contained"}
            onClick={() => {
              setShowUpdateProductsModal(false);
              sendUpdateTax(true);
            }}
            autoFocus
          >
            {translation.yes}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showDeactivate}
        onClose={() => {
          setShowDeactivate(false);
          setDeactivatingTax(null);
          setLoadingUpdate(false);
          setDeactivateError(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translation.taxes.deactivateTaxTitle}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText id="alert-dialog-description">
              {translation.taxes.deactivateTaxDescription}
            </DialogContentText>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={updateProductsPrices}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setUpdateProductsPrices(event.target.checked);
                    }}
                  />
                }
                label={translation.taxes.updateProductsPriceTitle}
              />
            </FormGroup>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
              <InputLabel id={"newtax-select-standard-label"}>
                {translation.products.taxPercentageField}
              </InputLabel>

              <Select
                name="tax"
                labelId={"newtax-select-standard-label"}
                id={"newtax-select-standard"}
                error={deactivateError}
                label={translation.products.taxPercentageField}
                value={newTaxAfterDeactivate}
                onChange={(e) => {
                  setNewTaxAfterDeactivate(e.target.value);
                }}
              >
                {taxes
                  .filter((tax) => {
                    if (deactivatingTax) {
                      return tax["@id"] !== deactivatingTax["@id"];
                    } else return false;
                  })
                  .map((tax) => {
                    return (
                      <MenuItem
                        value={"/taxes/" + tax.id}
                        key={"deactivatingTax" + tax.id}
                      >
                        {tax.taxPercentage + "%"}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            onClick={() => {
              setShowDeactivate(false);
              setDeactivatingTax(null);
              setLoadingUpdate(false);
              setDeactivateError(false);
            }}
          >
            {translation.closeButton}
          </Button>
          <Button
            variant={"contained"}
            onClick={() => {
              if (newTaxAfterDeactivate !== "") {
                setShowDeactivate(false);
                updateTax({
                  ...deactivatingTax,
                  isActive: false,
                  updateProductsPrice: updateProductsPrices,
                  newTax: newTaxAfterDeactivate,
                })
                  .then((res) => {
                    setSuccessMessage(translation.deletedMessage);
                    setLoadingUpdate(false);
                    setDeactivatingTax(null);
                    setUpdateTrigger(
                      (updateTrigger: boolean) => !updateTrigger
                    );
                  })
                  .catch((e) => {
                    setErrorMessage(errorMessage(e));
                    setDeactivatingTax(null);
                    setLoadingUpdate(false);
                  });
              } else {
                setDeactivateError(true);
              }
            }}
          >
            {translation.saveButton}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ArrayNumberPercentageField;
