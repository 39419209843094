import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import {
  createConsent,
  createSettings,
  createTax,
  getConsentByTenant,
  getSettingsByTenant,
  getTaxesByTenant,
  updateConsent,
  updateSettings,
  updateTenant,
} from "services/tenants";

import PapperCollapse from "components/PaperCollapse";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { Box, Stack } from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { IConsent, ISettings, ITax } from "interfaces";
import SwitchField from "components/Form/SwitchField/SwitchField";
import ArrayNumberPercentageField from "components/Form/ArrayNumberPercentageField";
import { LoadingButton } from "@mui/lab";
import { errorMessage } from "helpers";
import { useTenantContext } from "components/contexts/TenantContext";
import MarketingMenu from "components/Menu/MarketingMenu";
import FormTextField from "components/Form/FormTextField/FormTextField";

const Consent = () => {
  const { selectedTenant, tenants, updateTrigger, setUpdateTrigger } =
    useUserContext();

  const { loading, setLoading } = useLoadingContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const [consent, setConsent] = useState<IConsent | null>(null);

  const [isEditingTax, setIsEditingTax] = useState(false);
  const translation = useTranslation();

  useEffect(() => {
    setLoading(true);

    if (tenants !== null) {
      getConsentByTenant(tenants[selectedTenant].id)
        .then((res) => {
          if (res.data["hydra:member"].length > 0) {
            setConsent(res.data["hydra:member"][0]);
          } else {
            setConsent(null);
          }
          setLoading(false);
        })
        .catch((e) => setErrorMessage(errorMessage(e)));
    }
  }, [selectedTenant, tenants, updateTrigger]);

  const submitForm = async (values: any, actions: any) => {
    try {
      if (!consent) {
        createConsent({
          tenant: "/tenants/" + tenants[selectedTenant].id,
          modalText: values.modalText,
          acceptAll: values.acceptAll,
          rejectAll: values.rejectAll,
          confirm: values.confirm,
          marketingName: values.marketingName,
          marketingDescription: values.marketingDescription,
        })
          .then(() => {
            setUpdateTrigger(!updateTrigger);
            setSuccessMessage(translation.savedMessage);
            actions.setSubmitting(false);
          })
          .catch((e) => {
            setErrorMessage(errorMessage(e));
            actions.setSubmitting(false);
          });
      } else {
        updateConsent({
          id: consent.id,
          modalText: values.modalText,
          acceptAll: values.acceptAll,
          rejectAll: values.rejectAll,
          confirm: values.confirm,
          marketingName: values.marketingName,
          marketingDescription: values.marketingDescription,
        })
          .then(() => {
            setUpdateTrigger(!updateTrigger);

            setSuccessMessage(translation.savedMessage);
            actions.setSubmitting(false);
          })
          .catch((e) => {
            setErrorMessage(errorMessage(e));
            actions.setSubmitting(false);
          });
      }
    } catch (e) {
      actions.setSubmitting(false);
      setErrorMessage(errorMessage(e));
      setUpdateTrigger(!updateTrigger);
      return;
    }
  };

  return (
    <>
      <MarketingMenu />
      <PapperCollapse title={translation.consentTitle}>
        <Formik
          initialValues={{
            modalText: consent ? consent.modalText : "",
            acceptAll: consent ? consent.acceptAll : "",
            rejectAll: consent ? consent.rejectAll : "",
            confirm: consent ? consent.confirm : "",
            marketingName: consent ? consent.marketingName : "",
            marketingDescription: consent ? consent.marketingDescription : "",
          }}
          enableReinitialize
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            submitForm(values, actions);
          }}
        >
          {({ isSubmitting, submitForm, values, resetForm }) => (
            <Box sx={{ height: "100%" }}>
              <Stack spacing={2} sx={{ width: 600 }}>
                <FormTextField
                  multiline
                  name="modalText"
                  label={translation.consent.modalTextLabel}
                />

                <FormTextField
                  name="acceptAll"
                  label={translation.consent.acceptAll}
                />

                <FormTextField
                  name="rejectAll"
                  label={translation.consent.rejectAll}
                />
                <FormTextField
                  name="confirm"
                  label={translation.consent.confirm}
                />
                <FormTextField
                  name="marketingName"
                  label={translation.consent.marketingName}
                />
                <FormTextField
                  multiline
                  name="marketingDescription"
                  label={translation.consent.marketingDescription}
                />

                <Box display={"flex"} justifyContent="flex-start">
                  <LoadingButton
                    type="submit"
                    onClick={submitForm}
                    variant="contained"
                    loading={isSubmitting}
                    disabled={isEditingTax}
                  >
                    {translation.save}
                  </LoadingButton>
                </Box>
              </Stack>
            </Box>
          )}
        </Formik>
      </PapperCollapse>
    </>
  );
};

export default Consent;
