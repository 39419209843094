import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useTranslation from "components/customHooks/translations";
import { getSubscription } from "services/products";
import LoadingIcon from "components/Feedback/LoadingIcon";
import PageHeader from "components/PageHeader";
import SubscriptionsForm from "./SubscriptionsForm";
import { errorMessage } from "helpers";
import { useSnackbarContext } from "components/contexts/SnackbarContext";

const CopySubscriptions = () => {
  const [subscription, setSubscription] = useState<any>(null);
  const [subscriptionisLoading, setSubscriptionisLoading] = useState(true);
  const translation = useTranslation();
  const { subscriptionId } = useParams();
  const { setErrorMessage } = useSnackbarContext();

  useEffect(() => {
    if (subscriptionId !== undefined) {
      getSubscription(subscriptionId)
        .then((subscription) => {
          if (subscription.data) {
            subscription.data.id = null;
            subscription.data.sku = "";

            if (subscription.data.discount) {
              subscription.data.discount.id = undefined;
              subscription.data.discount["@id"] = undefined;
            }
            setSubscription(subscription.data);
          }
        })
        .catch((e) => setErrorMessage(errorMessage(e)));
    }
  }, [subscriptionId]);

  useEffect(() => {
    if (subscription) {
      setSubscriptionisLoading(false);
    }
  }, [subscription]);

  return (
    <>
      {subscriptionisLoading && subscription ? (
        <LoadingIcon />
      ) : (
        <>
          <PageHeader>{translation.editSubscriptionTitle}</PageHeader>
          <SubscriptionsForm
            subscriptionToEdit={subscription}
          ></SubscriptionsForm>
        </>
      )}
    </>
  );
};

export default CopySubscriptions;
