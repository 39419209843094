import { useField } from "formik";
import ReactQuill, { Quill } from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";

import ImageUploader from "helpers/quill-image-uploader/dist";

import { useMemo } from "react";
import { createImage } from "services/tenants";
import { errorMessage } from "helpers";
import { Stack, Typography } from "@mui/material";

const BlockEmbed = Quill.import("blots/block/embed");

class ImageBlot extends BlockEmbed {
  static create(value) {
    const node = super.create();
    if (typeof value === "string") {
      node.setAttribute("src", value);
    } else {
      node.setAttribute("src", value.url);
    }
    node.setAttribute("style", "max-width: 100%; height: auto;");
    return node;
  }

  static value(node) {
    return {
      alt: node.getAttribute("alt"),
      url: node.getAttribute("src"),
    };
  }
}
ImageBlot.blotName = "image";
ImageBlot.tagName = "img";
Quill.register(ImageBlot);

Quill.register("modules/imageUploader", ImageUploader);

class Video extends BlockEmbed {
  static create(value) {
    let node = super.create(value);
    let iframe = document.createElement("iframe");
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", true);
    iframe.setAttribute("style", "width: 560px; height: 315px;");
    iframe.setAttribute("src", value);
    node.appendChild(iframe);
    return node;
  }

  static value(domNode) {
    return domNode.firstChild.getAttribute("src");
  }
}
Video.blotName = "video";
Video.className = "ql-video";
Video.tagName = "div";

Quill.register({
  "formats/video": Video,
});

const getModules = () => {
  return {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike"],
      ["link", "blockquote", "code"],
      [
        {
          list: "ordered",
        },
        {
          list: "bullet",
        },
        { align: [] },
      ],
      ["image", "video"],
    ],
    imageUploader: {
      upload: (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            createImage({
              contentUrl: reader.result,
            })
              .then((response) => {
                resolve(response.data.contentUrl);
              })
              .catch((e) => {
                reject(errorMessage(e));
              });
          };
        });
      },
    },
  };
};

const QuillTextField = ({
  name,
  disabled = false,
  label = "",
  noMedia = false,
}) => {
  const [field, meta, helper] = useField(name);
  const isValid = !meta.error;
  const isInvalid = meta.touched && !isValid;

  const modules = useMemo(() => getModules(), []);

  return (
    <Stack spacing={2}>
      {label && <Typography>{label}</Typography>}

      <ReactQuill
        id={field.name}
        onChange={(value) => {
          helper.setValue(value);
        }}
        value={field.value}
        theme="snow"
        readOnly={disabled}
        modules={modules}
      />
    </Stack>
  );
};

export default QuillTextField;
