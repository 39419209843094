import { Card, Typography } from "@mui/material";
import { IAddress } from "interfaces";

const AddressCard = ({
  address,
  selected = false,
  onClick,
}: {
  address: IAddress;
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <>
      <Card
        sx={{
          backgroundColor: "grey.100",
          p: "1rem",
          m: "8px",
          position: "relative",
          boxShadow: "none",
          border: "1px solid",
          cursor: onClick ? "pointer" : "default",
          borderColor: selected ? "primary.main" : "transparent",
        }}
        onClick={onClick}
      >
        <Typography mb={0.5}>{address.label}</Typography>
        <Typography color="grey.700">{address.name}</Typography>
        {address.companyName && (
          <Typography color="grey.700">
            {address.companyName}
            {", "}
            {address.vatId}
          </Typography>
        )}

        <Typography color="grey.700">
          {address.streetAddress}
          {", "}
          {address.county}
          {", "}
          {address.city}
          {", "}
          {address.postalCode}
        </Typography>
        <Typography color="grey.700">{address.phoneNumber}</Typography>
      </Card>
    </>
  );
};

export default AddressCard;
