import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import DropZoneBulkProductImg from "components/Form/DropZoneBulkProductImg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IBulkUploadedProduct, IImageObject } from "interfaces";
import { useEffect, useState } from "react";
import { editProduct, getBulkUploadProducts } from "services/products";
import { useNavigate, useParams } from "react-router-dom";
import { routeNames } from "routes";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { errorMessage } from "helpers";

const BulkProductsImages = () => {
  const [bulkUploadedProducts, setBulkUploadedProducts] = useState<
    IBulkUploadedProduct[]
  >([]);
  const { setLoading } = useLoadingContext();
  const [openUploading, setOpenUploading] = useState(false);
  const [uploadedProductImagesCount, setUploadedProductImagesCount] =
    useState(0);
  const translation = useTranslation();
  const navigate = useNavigate();
  const { setErrorMessage } = useSnackbarContext();
  const { bulkUploadId } = useParams();

  useEffect(() => {
    if (bulkUploadId !== undefined) {
      setLoading(true);
      getBulkUploadProducts(bulkUploadId)
        .then((products) => {
          if (products.data) {
            setBulkUploadedProducts(products.data.products);
            setLoading(false);
          }
        })
        .catch((e) => setErrorMessage(errorMessage(e)));
    }
  }, [bulkUploadId]);

  const setNewUploadedProductImages = (
    images: IImageObject[],
    index: number
  ) => {
    const newProduct = JSON.parse(JSON.stringify(bulkUploadedProducts[index]));
    newProduct.images = JSON.parse(JSON.stringify(images));
    const newBulkUploadedProducts = JSON.parse(
      JSON.stringify(bulkUploadedProducts)
    );
    newBulkUploadedProducts[index] = newProduct;
    setBulkUploadedProducts(newBulkUploadedProducts);
  };

  const handleUploadImages = async () => {
    setOpenUploading(true);
    for (let i = 0; i < bulkUploadedProducts.length; i++) {
      try {
        await editProduct(bulkUploadedProducts[i]);
        setUploadedProductImagesCount((value) => value + 1);
      } catch (e) {
        setErrorMessage(errorMessage(e));
        setUploadedProductImagesCount((value) => value + 1);
      }
    }
  };

  const handleCloseUploading = () => {
    setOpenUploading(false);
    navigate(routeNames.products);
  };

  return (
    <>
      <Typography variant="h3">
        {translation.productsExportImport.bulkUploadImages}
      </Typography>
      {bulkUploadedProducts.length > 0 ? (
        <>
          {bulkUploadedProducts &&
            bulkUploadedProducts.map((uploadedProduct, index) => (
              <Paper
                key={"uploaded-product-" + uploadedProduct.id}
                sx={{ p: 2, my: 2 }}
              >
                <Typography variant="body2" sx={{ mb: 2 }}>
                  {uploadedProduct.name + " - " + uploadedProduct.sku}
                </Typography>
                <DropZoneBulkProductImg
                  label={translation.products.imagesField}
                  labelDescription={translation.products.imagesDescriptionField}
                  uploadedProduct={uploadedProduct}
                  setNewUploadedProductImages={setNewUploadedProductImages}
                  productIndex={index}
                  productName={uploadedProduct.name}
                ></DropZoneBulkProductImg>
              </Paper>
            ))}
          <Stack direction="row" spacing={2}>
            <LoadingButton
              variant={"contained"}
              onClick={() => handleUploadImages()}
            >
              {translation.productsExportImport.uploadImages}
            </LoadingButton>
            <Button
              variant="outlined"
              onClick={() => navigate(routeNames.productsExportImport)}
            >
              {translation.productsExportImport.goBack}
            </Button>
          </Stack>
          <Dialog
            open={openUploading}
            onClose={() => {
              return;
            }}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {translation.productsExportImport.uploadingImages}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {uploadedProductImagesCount !== bulkUploadedProducts.length
                  ? translation.productsExportImport.doNotClose
                  : translation.productsExportImport.done}
              </DialogContentText>
              <DialogContentText id="alert-dialog-description">
                {uploadedProductImagesCount +
                  "/" +
                  bulkUploadedProducts.length +
                  translation.productsExportImport.uploaded}
              </DialogContentText>
              <Box sx={{ width: "100%", my: 2 }}>
                <LinearProgress
                  variant="determinate"
                  value={Math.round(
                    (100 * uploadedProductImagesCount) /
                      bulkUploadedProducts.length
                  )}
                />
              </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
              {uploadedProductImagesCount === bulkUploadedProducts.length && (
                <Button variant="outlined" onClick={handleCloseUploading}>
                  {translation.productsExportImport.closeAndGoToProducts}
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default BulkProductsImages;
