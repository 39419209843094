import React, { useEffect, useState } from "react";
import useTranslation from "components/customHooks/translations";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Popover,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { ICourier } from "interfaces";
import { useUserContext } from "components/contexts/UserContext";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { getCouriersByTenant } from "services/tenants";
import { availableCouriers } from "helpers/couriers";
import CourierForm from "./CourierForm";
import {
  Add,
  Dangerous,
  EditOutlined,
  Warning,
  WarningAmber,
} from "@mui/icons-material";
import { errorMessage } from "helpers";
import { useSnackbarContext } from "components/contexts/SnackbarContext";

const CourierIntegrations = () => {
  const { selectedTenant, tenants } = useUserContext();
  const { setLoading } = useLoadingContext();
  const [couriers, setCouriers] = useState<ICourier[]>([]);

  const [showNewCourier, setShowNewCourier] = useState(false);
  const [courierToEdit, setCourierToEdit] = useState<ICourier | null>(null);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const t = useTranslation();
  const { setErrorMessage } = useSnackbarContext();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    setLoading(true);

    if (tenants !== null) {
      getCouriersByTenant(tenants[selectedTenant].id)
        .then((res) => {
          setCouriers(res.data["hydra:member"]);
          setLoading(false);
        })
        .catch((e) => setErrorMessage(errorMessage(e)));
    }
  }, [selectedTenant, tenants, updateTrigger]);

  return (
    <Stack>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => setShowNewCourier(true)}
        >
          {t.shipping.newCourierButton}
        </Button>
      </Box>
      {couriers.map((courier) => (
        <Box key={courier.id}>
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <IconButton
              onClick={() => {
                setCourierToEdit(courier);
                setShowNewCourier(true);
              }}
            >
              <EditOutlined />
            </IconButton>
            <Typography>{courier.name}</Typography>
            {courier.settings.length === 0 && (
              <IconButton
                color="info"
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <WarningAmber color="warning" />
              </IconButton>
            )}
          </Stack>
          <Divider sx={{ my: 2 }} />
        </Box>
      ))}
      {couriers.length === 0 && (
        <Typography>{t.shipping.noCouriers}</Typography>
      )}
      <CourierForm
        showNewCourier={showNewCourier}
        setShowNewCourier={setShowNewCourier}
        courierToEdit={courierToEdit}
        setCourierToEdit={setCourierToEdit}
        updateTrigger={updateTrigger}
        setUpdateTrigger={setUpdateTrigger}
      ></CourierForm>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          sx={{
            p: 1,
            color: "text.secondary",
            whiteSpace: "pre-line",
          }}
        >
          {t.shipping.finishSetUp}
        </Typography>
      </Popover>
    </Stack>
  );
};

export default CourierIntegrations;
