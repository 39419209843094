import React from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

import useTranslation from "components/customHooks/translations";
import FormNumberField from "../FormNumberField";
import { useUserContext } from "components/contexts/UserContext";
import FormTextField from "../FormTextField/FormTextField";
import { IInvoiceLine, ITax } from "interfaces";
import SwitchField from "../SwitchField/SwitchField";
import { calculateTaxAmount } from "helpers";
import { exchangeCurrency } from "services/products";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { DeleteOutline, Percent } from "@mui/icons-material";

const ArrayInvoiceLinesField = ({
  name,
  taxes,
  setOpenSetDiscount,
  setIndexForDiscount,
  hasGeneralDiscount = false,
}: {
  name: string;
  taxes: ITax[];
  setOpenSetDiscount: React.Dispatch<React.SetStateAction<boolean>>;
  setIndexForDiscount: React.Dispatch<React.SetStateAction<number | null>>;
  hasGeneralDiscount: boolean;
}) => {
  const translation = useTranslation();
  const { selectedTenant, tenants } = useUserContext();
  const { setLoading } = useLoadingContext();
  const [field, _meta, helper] = useField(name);
  const [currencyField] = useField("currency");

  return (
    <>
      <Stack>
        {field.value.map((value: IInvoiceLine, index: number) => {
          let amount = 0;
          if (value.currency == currencyField.value) {
            amount =
              value.qty && value.unitPrice
                ? Math.round(
                    (value.qty * value.unitPrice + Number.EPSILON) * 100
                  ) / 100
                : 0;
          } else {
            amount =
              value.qty && value.exchangeRate && value.originalPrice
                ? Math.round(
                    (value.exchangeRate * value.qty * value.originalPrice +
                      Number.EPSILON) *
                      100
                  ) / 100
                : 0;
          }
          let taxPercentage = 0;
          for (const tax of taxes) {
            if (value.vatPercentage === tax["@id"]) {
              taxPercentage = Number(tax.taxPercentage);
            }
          }
          let vat = 0;
          if (value.priceHasVat !== undefined) {
            vat =
              amount && taxPercentage
                ? calculateTaxAmount(amount, taxPercentage, value.priceHasVat)
                : 0;
          }
          return (
            <Stack
              key={"invoiceLines-" + index}
              sx={{
                backgroundColor: index % 2 === 1 ? "#F5F5F5" : "white",
                paddingX: 2,
                pt: 3,
              }}
              spacing={4}
            >
              <Stack
                direction={"row"}
                spacing={2}
                flexWrap={"wrap"}
                justifyContent={"space-between"}
              >
                <Stack spacing={2}>
                  <FormControl sx={{ minWidth: 400 }}>
                    <TextField
                      variant="standard"
                      label={translation.invoices.item}
                      value={value.item}
                      multiline
                      onChange={(e) => {
                        const oldValues = field.value.map((v: any) => {
                          return { ...v };
                        });
                        oldValues[index].item = e.target.value;
                        if (index === oldValues.length - 1) {
                          oldValues.push({
                            item: "",
                            itemDescription: "",
                            qty: "",
                            unitPrice: "",
                            originalPrice: 0,
                            exchangeRate: 1,
                            currency: currencyField.value,
                            amount: "",
                            totalVat: "",
                            priceHasVat:
                              tenants[selectedTenant].settings.taxIncluded,
                            vatPercentage: taxes[0]["@id"],
                            um: "pcs",
                            discount: false,
                          });
                        }
                        if (e.target.value === "") {
                          oldValues.splice(index, 1);
                        }
                        helper.setValue([...oldValues]);
                      }}
                    />
                  </FormControl>
                  <FormTextField
                    disabled={!value.item}
                    label={translation.invoices.description}
                    name={"invoiceLines[" + index + "].itemDescription"}
                  />
                </Stack>

                <FormControl sx={{ maxWidth: 70 }}>
                  <FormTextField
                    disabled={!value.item}
                    label={translation.invoices.um}
                    name={"invoiceLines[" + index + "].um"}
                  />
                </FormControl>
                <FormControl sx={{ maxWidth: 50 }}>
                  <FormNumberField
                    disabled={!value.item}
                    type="int"
                    label={translation.invoices.qty}
                    name={"invoiceLines[" + index + "].qty"}
                  />
                </FormControl>
                <Stack spacing={2}>
                  <Box>
                    <FormControl variant="standard" sx={{ minWidth: 100 }}>
                      <InputLabel id={"currency-" + index}>
                        {translation.invoices.currency}
                      </InputLabel>
                      <Select
                        disabled={!value.item}
                        value={value.currency}
                        labelId={"currency-" + index}
                        id={"currency-" + index}
                        label={translation.invoices.currency}
                        onChange={(e) => {
                          if (e.target.value !== currencyField.value) {
                            setLoading(true);
                            exchangeCurrency(
                              e.target.value,
                              currencyField.value,
                              1
                            ).then((res) => {
                              setLoading(false);
                              const rate = res.data.rate;
                              const oldValues = field.value.map((v: any) => {
                                return { ...v };
                              });
                              oldValues[index].currency = e.target.value;
                              oldValues[index].exchangeRate = rate;
                              oldValues[index].unitPrice = 0;
                              oldValues[index].originalPrice = 0;
                              oldValues[index].itemDescription =
                                "1 " +
                                e.target.value +
                                " = " +
                                rate +
                                currencyField.value;
                              helper.setValue([...oldValues]);
                            });
                          } else {
                            const oldValues = field.value.map((v: any) => {
                              return { ...v };
                            });
                            oldValues[index].currency = e.target.value;
                            helper.setValue([...oldValues]);
                          }
                        }}
                      >
                        {tenants[selectedTenant].settings.enabledCurrencies.map(
                          (currency: any) => {
                            return (
                              <MenuItem
                                value={currency}
                                key={"default-" + currency}
                              >
                                {currency}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  </Box>

                  {value.currency !== currencyField.value && (
                    <Box sx={{ maxWidth: 100 }}>
                      <TextField
                        value={value.exchangeRate}
                        variant={"standard"}
                        label={translation.invoices.exchangeRate}
                        disabled
                      />
                    </Box>
                  )}
                </Stack>

                {value.currency !== currencyField.value && (
                  <Stack spacing={2}>
                    <FormControl sx={{ maxWidth: 120 }}>
                      <FormNumberField
                        disabled={
                          !value.item || value.currency == currencyField.value
                        }
                        type="float"
                        allowNegative={value.discount}
                        label={
                          translation.invoices.unitPrice +
                          " (" +
                          value.currency +
                          ")"
                        }
                        name={"invoiceLines[" + index + "].originalPrice"}
                      />
                    </FormControl>
                    <FormControl sx={{ maxWidth: 120 }}>
                      <TextField
                        value={
                          value.exchangeRate !== undefined
                            ? value.originalPrice
                              ? Math.round(
                                  (value.exchangeRate *
                                    value.qty *
                                    value.originalPrice +
                                    Number.EPSILON) *
                                    100
                                ) / 100
                              : 0
                            : 0
                        }
                        variant={"standard"}
                        label={
                          translation.invoices.unitPrice +
                          " (" +
                          currencyField.value +
                          ")"
                        }
                        disabled
                      />
                    </FormControl>
                  </Stack>
                )}

                {value.currency == currencyField.value && (
                  <FormControl sx={{ maxWidth: 120 }}>
                    <FormNumberField
                      disabled={
                        !value.item || value.currency !== currencyField.value
                      }
                      type="float"
                      allowNegative={value.discount}
                      label={
                        translation.invoices.unitPrice +
                        " (" +
                        currencyField.value +
                        ")"
                      }
                      name={"invoiceLines[" + index + "].unitPrice"}
                    />
                  </FormControl>
                )}
                <Stack
                  justifyContent={
                    value.currency !== currencyField.value
                      ? "flex-end"
                      : "flex-start"
                  }
                >
                  <FormControl
                    sx={{
                      maxWidth: 150,
                      bottom: 0,
                    }}
                  >
                    <TextField
                      value={amount}
                      variant={"standard"}
                      label={translation.invoices.amount}
                      disabled
                    />
                  </FormControl>
                </Stack>
                <Box sx={{ paddingTop: "12px" }}>
                  <SwitchField
                    disabled={!value.item}
                    name={"invoiceLines[" + index + "].priceHasVat"}
                    label={translation.invoices.vatIncluded}
                  ></SwitchField>
                </Box>
                <Box>
                  <FormControl sx={{ minWidth: 100 }}>
                    <InputLabel id={"tax-label-" + index}>
                      {translation.invoices.tax}
                    </InputLabel>
                    <Select
                      disabled={!value.item}
                      defaultValue={taxes[0]["@id"]}
                      labelId={"tax-label-" + index}
                      id={"tax-value-" + index}
                      label={translation.invoices.tax}
                      onChange={(e) => {
                        const oldValues = field.value.map((v: any) => {
                          return { ...v };
                        });
                        oldValues[index].vatPercentage = e.target.value;
                        helper.setValue([...oldValues]);
                      }}
                    >
                      {taxes.map((tax) => (
                        <MenuItem
                          key={tax["@id"] + "-" + index}
                          value={tax["@id"]}
                        >
                          {tax.taxPercentage}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Stack spacing={2} justifyContent={"space-between"}>
                  <FormControl sx={{ maxWidth: 150 }}>
                    <TextField
                      value={vat}
                      variant={"standard"}
                      label={
                        translation.invoices.totalVat +
                        " (" +
                        currencyField.value +
                        ")"
                      }
                      disabled
                    />
                  </FormControl>
                </Stack>
                <Stack spacing={2} justifyContent={"center"}>
                  <IconButton
                    color="warning"
                    disabled={!value.item}
                    onClick={() => {
                      const oldValues = field.value.map((v: any) => {
                        return { ...v };
                      });
                      oldValues.splice(index, 1);
                      helper.setValue([...oldValues]);
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                  {!value.discount && (
                    <IconButton
                      size="small"
                      color="primary"
                      disabled={!value.item || hasGeneralDiscount}
                      onClick={() => {
                        setOpenSetDiscount(true);
                        setIndexForDiscount(index);
                      }}
                    >
                      <Percent></Percent>
                    </IconButton>
                  )}
                </Stack>
              </Stack>
              <Divider></Divider>
            </Stack>
          );
        })}
      </Stack>
    </>
  );
};

export default ArrayInvoiceLinesField;
