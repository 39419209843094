import React, { useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import useTranslation from "components/customHooks/translations";
import { Delete } from "@mui/icons-material";
import { IOrderProduct, IProduct, IVariation } from "interfaces";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { exchangeCurrency, searchProducts } from "services/products";
import { errorMessage, isDiscountActive } from "helpers";
import defaultImage from "assets/img/placeholder-image.png";
import discountImage from "assets/img/discount.png";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { useUserContext } from "components/contexts/UserContext";
import { formDrawerWidth } from "components/Form/constants";
import OrderPrice from "components/Orders/OrderPrice";
import { useLoadingContext } from "components/contexts/LoadingContext";
import ProductSearch from "./ProductSearch";

interface CustomProps {
  onChange: (event: { target: { value: string } }) => void;
  name: string | undefined;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        decimalScale={2}
        allowNegative={false}
        valueIsNumericString
      />
    );
  }
);

const NumericPercentageFormatCustom = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericPercentageFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      max={100}
      min={0}
      isAllowed={(values) => {
        const { floatValue } = values;
        if (floatValue) return floatValue <= 100;
        else return true;
      }}
      allowNegative={false}
      decimalScale={2}
      valueIsNumericString
      suffix="%"
    />
  );
});

const EditOrderProducts = ({
  orderProducts,
  setOrderProducts,
}: {
  orderProducts: IOrderProduct[];
  setOrderProducts: any;
}) => {
  const { selectedTenant, tenants } = useUserContext();
  const { setErrorMessage } = useSnackbarContext();
  const { setLoading } = useLoadingContext();
  const [openSetDiscount, setOpenSetDiscount] = useState<boolean>(false);
  const [subtotal, setSubtotal] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [total, setTotal] = useState(0);
  const [indexForDiscount, setIndexForDiscount] = useState<number | null>(null);
  const [discount, setDiscount] = useState<any>("");
  const [discountPercentage, setDiscountPercentage] = useState<any>("");
  const [productSearch, setProductSearch] = useState("");
  const [searchedProducts, setSearchedProducts] = useState<IProduct[]>([]);
  const [discountFieldFocus, setDiscountFieldFocus] = useState<
    "" | "percentage" | "amount"
  >("");
  const [hasOrderDiscount, setHasOrderDiscount] = useState(false);
  const [loadingSearchedProducts, setLoadingSearchedProducts] = useState(false);
  const translation = useTranslation();

  const theme = useTheme();

  const tempTotal = useMemo(
    () => Math.round((subtotal - totalDiscount + Number.EPSILON) * 100) / 100,
    [subtotal, totalDiscount]
  );

  useEffect(() => {
    const getData = setTimeout(() => {
      if (productSearch.length > 1) {
        setLoadingSearchedProducts(true);
        setSearchedProducts([]);
        searchProducts(productSearch, tenants[selectedTenant].id)
          .then((response) => {
            setLoadingSearchedProducts(false);

            setSearchedProducts(response.data["hydra:member"]);
          })
          .catch((e) => {
            setLoadingSearchedProducts(false);
            setErrorMessage(errorMessage(e));
          });
      }
    }, 1000);

    return () => clearTimeout(getData);
  }, [productSearch]);

  useEffect(() => {
    if (!discount) {
      setDiscountPercentage("");
      return;
    }
    if (indexForDiscount !== null) {
      const product = orderProducts[indexForDiscount].product;

      if (product) {
        if (tenants[selectedTenant].settings.taxIncluded) {
          setDiscountPercentage(
            Math.round(
              (discount / orderProducts[indexForDiscount].price.netPrice) *
                10000
            ) / 100
          );
        } else {
          setDiscountPercentage(
            Math.round(
              (discount / orderProducts[indexForDiscount].price.grossPrice) *
                10000
            ) / 100
          );
        }
      }
    } else {
      setDiscountPercentage(Math.round((discount / tempTotal) * 10000) / 100);
    }
  }, [discount]);

  const handleCloseNewProduct = () => {
    setSearchedProducts([]);
    setProductSearch("");
  };

  const handleAddProduct = async (
    product: IProduct,
    variation?: IVariation
  ) => {
    const newOrderProducts = [...orderProducts];
    let netPrice = 0;
    let grossPrice = 0;
    if (product.isVariable && variation) {
      netPrice = variation.netPrice;
      grossPrice = variation.grossPrice;
    } else {
      netPrice = product.netPrice;
      grossPrice = product.grossPrice;
    }
    let rate = undefined;
    const defaultCurency = tenants[selectedTenant].settings.defaultCurrency;
    let netDiscount = undefined;
    let grossDiscount = undefined;
    if (isDiscountActive(product.productDiscount)) {
      if (product.productDiscount?.netPrice) {
        netDiscount = netPrice - product.productDiscount?.netPrice;
      }
      if (product.productDiscount?.grossPrice) {
        grossDiscount = netPrice - product.productDiscount?.grossPrice;
      }
    }
    if (defaultCurency !== product.priceCurrency) {
      setLoading(true);
      const responseNet = await exchangeCurrency(
        product.priceCurrency,
        defaultCurency,
        netPrice
      );
      netPrice = responseNet.data.amount;
      rate = responseNet.data.rate;
      const responseGross = await exchangeCurrency(
        product.priceCurrency,
        defaultCurency,
        grossPrice
      );

      setLoading(false);
      grossPrice = responseGross.data.amount;
      if (isDiscountActive(product.productDiscount)) {
        if (netDiscount) {
          netDiscount =
            Math.round((netDiscount * rate + Number.EPSILON) * 100) / 100;
        }
        if (grossDiscount) {
          grossDiscount =
            Math.round((grossDiscount * rate + Number.EPSILON) * 100) / 100;
        }
      }
    }
    newOrderProducts.push({
      product: product,
      variation: variation,
      quantity: 1,
      price: {
        netPrice: netPrice,
        grossPrice: grossPrice,
        priceCurrency: defaultCurency,
      },
      rate: rate,
      netDiscount: netDiscount,
      grossDiscount: grossDiscount,
      hasDiscount: isDiscountActive(product.productDiscount),
    });
    setOrderProducts(newOrderProducts);
    handleCloseNewProduct();
  };

  const removeDiscount = (index: number) => {
    const newOrderProducts = [...orderProducts];
    newOrderProducts[index].hasDiscount = false;
    newOrderProducts[index].netDiscount = undefined;
    newOrderProducts[index].grossDiscount = undefined;
    setOrderProducts(newOrderProducts);
  };

  useEffect(() => {
    let total = 0;
    let subtotal = 0;
    let totalDiscount = 0;
    let hasOrderDiscountFlag = false;
    orderProducts.forEach((orderProduct) => {
      if (orderProduct.product) {
        if (tenants[selectedTenant].settings.taxIncluded) {
          subtotal =
            subtotal + orderProduct.price.netPrice * orderProduct.quantity;
        } else {
          subtotal =
            subtotal + orderProduct.price.grossPrice * orderProduct.quantity;
        }
      }
      if (orderProduct.hasDiscount) {
        if (
          orderProduct.product == null &&
          orderProduct.productSnapshot == null
        ) {
          hasOrderDiscountFlag = true;
        }
        if (tenants[selectedTenant].settings.taxIncluded) {
          if (orderProduct.netDiscount) {
            totalDiscount =
              totalDiscount + orderProduct.netDiscount * orderProduct.quantity;
          }
        } else {
          if (orderProduct.grossDiscount) {
            totalDiscount =
              totalDiscount +
              orderProduct.grossDiscount * orderProduct.quantity;
          }
        }
      }
    });
    setHasOrderDiscount(hasOrderDiscountFlag);
    subtotal = Math.round((subtotal + Number.EPSILON) * 100) / 100;
    totalDiscount = Math.round((totalDiscount + Number.EPSILON) * 100) / 100;
    total = Math.round((subtotal - totalDiscount + Number.EPSILON) * 100) / 100;

    setTotal(total);
    setSubtotal(subtotal);
    setTotalDiscount(totalDiscount);
  }, [orderProducts]);

  return (
    <>
      <Box>
        <Stack spacing={2}>
          <ProductSearch handleAddProduct={handleAddProduct} />
          <Typography variant="h5" sx={{ mb: 3 }}>
            {translation.order.products}
          </Typography>
          <Stack spacing={2}>
            {orderProducts.map((orderProduct, index) => {
              const discountAmount = orderProduct.netDiscount
                ? tenants[selectedTenant].settings.taxIncluded
                  ? orderProduct.netDiscount
                  : orderProduct.grossDiscount
                : null;
              if (orderProduct.product) {
                const price = tenants[selectedTenant].settings.taxIncluded
                  ? orderProduct.price.netPrice
                  : orderProduct.price.grossPrice;
                const currency = orderProduct.price.priceCurrency;
                const originalPrice = tenants[selectedTenant].settings
                  .taxIncluded
                  ? orderProduct.product.isVariable && orderProduct.variation
                    ? orderProduct.variation.netPrice
                    : orderProduct.product.netPrice
                  : orderProduct.product.isVariable && orderProduct.variation
                  ? orderProduct.variation.grossPrice
                  : orderProduct.product.grossPrice;
                return (
                  <Box key={"orderProduct-" + orderProduct.product.sku}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Stack direction={"row"} spacing={2}>
                          <Card
                            sx={{
                              minWidth: 75,
                              maxWidth: 75,
                              height: 75,
                            }}
                          >
                            <CardMedia
                              component="img"
                              height="75"
                              width="75"
                              image={
                                orderProduct.product.images.length > 0
                                  ? orderProduct.product.images[0].contentUrl
                                  : defaultImage
                              }
                            ></CardMedia>
                          </Card>
                          <Stack justifyContent={"space-between"}>
                            <Typography>{orderProduct.product.name}</Typography>
                            {orderProduct.product.isVariable &&
                              orderProduct.variation && (
                                <Stack direction="row">
                                  <Box
                                    sx={{
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      py: 1,
                                      px: 2,
                                      borderRadius: "0 8px",
                                    }}
                                  >
                                    <Typography color="white">
                                      {orderProduct.variation.name}
                                    </Typography>
                                  </Box>
                                </Stack>
                              )}

                            {orderProduct.product.hasStock && (
                              <Typography variant={"caption"}>
                                {orderProduct.product.stockCount} in stock
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={1}>
                        <TextField
                          label="QTY"
                          sx={{
                            width: "50px",
                          }}
                          onChange={(event) => {
                            const newOrderProducts = [...orderProducts];
                            newOrderProducts[index].quantity = parseInt(
                              event.target.value
                            );
                            if (event.target.value === "") {
                              newOrderProducts[index].quantity = 0;
                            }
                            setOrderProducts(newOrderProducts);
                          }}
                          InputProps={{
                            inputComponent: NumericFormatCustom as any,
                          }}
                          variant="standard"
                          value={orderProduct.quantity}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Stack>
                          <OrderPrice
                            price={price}
                            currency={currency}
                            originalPrice={originalPrice}
                            originalCurrency={
                              orderProduct.product.priceCurrency
                            }
                            rate={orderProduct.rate}
                            primary={false}
                          ></OrderPrice>
                        </Stack>
                      </Grid>
                      <Grid item xs={3}>
                        <Stack direction="row" justifyContent={"space-between"}>
                          <Stack>
                            <OrderPrice
                              price={price * orderProduct.quantity}
                              currency={currency}
                              originalPrice={
                                originalPrice * orderProduct.quantity
                              }
                              originalCurrency={
                                orderProduct.product.priceCurrency
                              }
                              rate={orderProduct.rate}
                            ></OrderPrice>
                          </Stack>
                          <Box>
                            <IconButton
                              color={"warning"}
                              onClick={() => {
                                const newOrderProducts = [...orderProducts];
                                newOrderProducts.splice(index, 1);
                                setOrderProducts(newOrderProducts);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>

                    {orderProduct.hasDiscount && discountAmount ? (
                      <Grid sx={{ mt: 1, mb: 2 }} container spacing={2}>
                        <Grid item xs={6}>
                          <Stack direction={"row"} spacing={2}>
                            <Card
                              sx={{
                                minWidth: 75,
                                maxWidth: 75,
                                height: 75,
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="75"
                                width="75"
                                image={discountImage}
                              ></CardMedia>
                            </Card>

                            <Typography>
                              {"Product Discount (" +
                                (tenants[selectedTenant].settings.taxIncluded
                                  ? Math.round(
                                      (discountAmount /
                                        orderProduct.price.netPrice) *
                                        10000
                                    ) / 100
                                  : Math.round(
                                      (discountAmount /
                                        orderProduct.price.grossPrice) *
                                        10000
                                    ) / 100) +
                                "%)"}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography sx={{ pt: 1 }} color={"text.secondary"}>
                            {orderProduct.quantity + " x"}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Stack>
                            <OrderPrice
                              price={discountAmount}
                              currency={currency}
                              originalPrice={
                                Math.round(
                                  (discountAmount /
                                    (orderProduct.rate as unknown as any)) *
                                    100
                                ) / 100
                              }
                              originalCurrency={
                                orderProduct.product.priceCurrency
                              }
                              rate={orderProduct.rate}
                              primary={false}
                              isDiscount={true}
                            ></OrderPrice>
                          </Stack>
                        </Grid>
                        <Grid item xs={3}>
                          <Stack
                            direction="row"
                            justifyContent={"space-between"}
                          >
                            <OrderPrice
                              price={discountAmount * orderProduct.quantity}
                              currency={currency}
                              originalPrice={
                                Math.round(
                                  ((discountAmount * orderProduct.quantity) /
                                    (orderProduct.rate as unknown as any)) *
                                    100
                                ) / 100
                              }
                              originalCurrency={
                                orderProduct.product.priceCurrency
                              }
                              rate={orderProduct.rate}
                              isDiscount={true}
                            ></OrderPrice>

                            <IconButton
                              color={"warning"}
                              onClick={() => {
                                removeDiscount(index);
                                setIndexForDiscount(null);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Stack>
                        </Grid>
                      </Grid>
                    ) : (
                      <Box
                        sx={{ p: 2 }}
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"flex-end"}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setOpenSetDiscount(true);
                            setIndexForDiscount(index);
                          }}
                          disabled={hasOrderDiscount}
                        >
                          {translation.order.productDiscount}
                        </Button>
                      </Box>
                    )}

                    <Divider></Divider>
                  </Box>
                );
              } else {
                return (
                  <Box key={"discount-" + index}>
                    {orderProduct.hasDiscount && discountAmount && (
                      <Stack
                        direction={"row"}
                        spacing={2}
                        justifyContent="space-between"
                        sx={{
                          paddingBottom: 2,
                        }}
                      >
                        <Stack direction={"row"} spacing={2}>
                          <Card
                            sx={{
                              minWidth: 75,
                              maxWidth: 75,
                              height: 75,
                            }}
                          >
                            <CardMedia
                              component="img"
                              height="75"
                              width="75"
                              image={discountImage}
                            ></CardMedia>
                          </Card>
                          <Typography>
                            {"Order Discount (" +
                              Math.round(
                                (discountAmount /
                                  (tempTotal + discountAmount)) *
                                  10000
                              ) /
                                100 +
                              "%)"}
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={2}>
                          <Stack direction="row" spacing={2}>
                            <Typography
                              sx={{
                                p: 1,
                              }}
                            >
                              {"-" +
                                discountAmount +
                                tenants[selectedTenant].settings
                                  .defaultCurrency}
                            </Typography>
                            <Box>
                              <IconButton
                                color={"warning"}
                                onClick={() => {
                                  const newOrderProducts = [...orderProducts];
                                  newOrderProducts.splice(index, 1);
                                  setOrderProducts(newOrderProducts);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Box>
                          </Stack>
                        </Stack>
                      </Stack>
                    )}
                    <Divider></Divider>
                  </Box>
                );
              }
            })}
            {orderProducts.length > 0 && (
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Box>
                  {!hasOrderDiscount && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setOpenSetDiscount(true);
                      }}
                    >
                      {translation.order.orderDiscount}
                    </Button>
                  )}
                </Box>

                <Stack spacing={1} alignItems={"flex-end"}>
                  <Stack direction={"row"} spacing={1}>
                    <Typography>
                      {translation.order.productsSubtotal}
                    </Typography>
                    <Box sx={{ width: "100px" }}>
                      <Typography align="right">{subtotal}</Typography>
                    </Box>
                    <Typography>
                      {tenants[selectedTenant].settings.defaultCurrency}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} spacing={1}>
                    <Typography>
                      {translation.order.productsTotalDiscount}
                    </Typography>
                    <Box sx={{ width: "100px" }}>
                      <Typography align="right">
                        {totalDiscount > 0 ? "-" : ""}
                        {totalDiscount}
                      </Typography>
                    </Box>
                    <Typography>
                      {tenants[selectedTenant].settings.defaultCurrency}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} spacing={1}>
                    <Typography>{translation.order.productsTotal}</Typography>
                    <Box sx={{ width: "100px" }}>
                      <Typography align="right">{total}</Typography>
                    </Box>
                    <Typography>
                      {tenants[selectedTenant].settings.defaultCurrency}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            )}
            {orderProducts.length == 0 && (
              <Typography color="text.secondary">
                {translation.products.noProductsAvailable}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Box>
      <Drawer
        anchor="right"
        open={openSetDiscount}
        onClose={() => {
          setOpenSetDiscount(false);
          if (indexForDiscount !== null) {
            removeDiscount(indexForDiscount);

            setIndexForDiscount(null);
          }
        }}
        sx={{
          "& .MuiDrawer-paper": { width: formDrawerWidth },
        }}
      >
        <Stack spacing={2} sx={{ p: 2 }}>
          <Typography variant="h5">
            {translation.order.applyDiscount}
          </Typography>
          {indexForDiscount !== null ? (
            <Stack spacing={2}>
              <TextField
                variant="standard"
                label={translation.order.discountPercentage}
                onFocus={() => setDiscountFieldFocus("percentage")}
                onBlur={() => setDiscountFieldFocus("")}
                onChange={(event) => {
                  if (event.target.value === "") {
                    setDiscount("");
                    return;
                  }
                  if (discountFieldFocus == "percentage") {
                    const percentage = parseFloat(event.target.value);

                    if (tenants[selectedTenant].settings.taxIncluded) {
                      setDiscount(
                        Math.round(
                          orderProducts[indexForDiscount].price.netPrice *
                            percentage
                        ) / 100
                      );
                    } else {
                      setDiscount(
                        Math.round(
                          orderProducts[indexForDiscount].price.grossPrice *
                            percentage
                        ) / 100
                      );
                    }
                  }
                }}
                value={discountPercentage ? discountPercentage : ""}
                placeholder="%"
                InputProps={{
                  inputComponent: NumericPercentageFormatCustom as any,
                }}
              ></TextField>

              <TextField
                variant="standard"
                label={translation.order.discountAmmount}
                value={discount}
                onFocus={() => setDiscountFieldFocus("amount")}
                onBlur={() => setDiscountFieldFocus("")}
                onChange={(event) => {
                  if (event.target.value === "") {
                    setDiscount("");
                    return;
                  }
                  const discountValue = parseFloat(event.target.value);

                  if (tenants[selectedTenant].settings.taxIncluded) {
                    if (
                      discountValue >
                      orderProducts[indexForDiscount].price.netPrice
                    ) {
                      setDiscount(
                        orderProducts[indexForDiscount].price.netPrice
                      );
                      return;
                    }
                  } else {
                    if (
                      discountValue >
                      orderProducts[indexForDiscount].price.grossPrice
                    ) {
                      setDiscount(
                        orderProducts[indexForDiscount].price.grossPrice
                      );
                      return;
                    }
                  }

                  setDiscount(discountValue);
                }}
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                }}
              ></TextField>
              {orderProducts[indexForDiscount].product && (
                <TextField
                  variant="standard"
                  label={translation.order.discountSalePrice}
                  onFocus={() => setDiscountFieldFocus("amount")}
                  onBlur={() => setDiscountFieldFocus("")}
                  onChange={(event) => {
                    if (event.target.value == "") {
                      setDiscount(
                        orderProducts[indexForDiscount].price.netPrice
                      );
                      return;
                    }
                    if (
                      (orderProducts[indexForDiscount].price.netPrice as any) -
                        parseFloat(event.target.value) <
                      0
                    ) {
                      setDiscount(0);
                    } else {
                      setDiscount(
                        (orderProducts[indexForDiscount].price
                          .netPrice as any) - parseFloat(event.target.value)
                      );
                    }
                  }}
                  InputLabelProps={{ shrink: true }}
                  value={
                    (orderProducts[indexForDiscount].price.netPrice as any) -
                    discount
                  }
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                ></TextField>
              )}
            </Stack>
          ) : (
            <Stack spacing={2}>
              <TextField
                variant="standard"
                label={translation.order.discountPercentage}
                onFocus={() => setDiscountFieldFocus("percentage")}
                onBlur={() => setDiscountFieldFocus("")}
                onChange={(event) => {
                  if (event.target.value === "") {
                    setDiscount("");
                    return;
                  }
                  const percentage = parseFloat(event.target.value);
                  if (discountFieldFocus == "percentage") {
                    setDiscount(Math.round(tempTotal * percentage) / 100);
                  }
                }}
                value={discountPercentage ? discountPercentage : ""}
                placeholder="%"
                InputProps={{
                  inputComponent: NumericPercentageFormatCustom as any,
                }}
              ></TextField>

              <TextField
                variant="standard"
                label={translation.order.discountAmmount}
                value={discount}
                onFocus={() => setDiscountFieldFocus("amount")}
                onBlur={() => setDiscountFieldFocus("")}
                onChange={(event) => {
                  if (event.target.value === "") {
                    setDiscount("");
                    return;
                  }
                  const discountValue = parseFloat(event.target.value);

                  if (discountValue > subtotal) {
                    setDiscount(subtotal);
                    return;
                  }

                  setDiscount(discountValue);
                }}
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                }}
              ></TextField>

              <TextField
                variant="standard"
                label={translation.order.subtotal}
                onFocus={() => setDiscountFieldFocus("amount")}
                onBlur={() => setDiscountFieldFocus("")}
                onChange={(event) => {
                  if (event.target.value == "") {
                    setDiscount(tempTotal);
                    return;
                  }
                  if (tempTotal - parseFloat(event.target.value) < 0) {
                    setDiscount(0);
                  } else {
                    setDiscount(tempTotal - parseFloat(event.target.value));
                  }
                }}
                InputLabelProps={{ shrink: true }}
                value={tempTotal - discount}
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                }}
              ></TextField>
            </Stack>
          )}

          <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
            <Button
              variant={"outlined"}
              onClick={() => {
                setOpenSetDiscount(false);
                if (indexForDiscount !== null) {
                  removeDiscount(indexForDiscount);

                  setIndexForDiscount(null);
                }
                setDiscount("");
              }}
            >
              {translation.closeButton}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (!discount) {
                  setErrorMessage(translation.order.discountAmmountRequired);
                  return;
                }
                if (indexForDiscount !== null) {
                  if (tenants[selectedTenant].settings.taxIncluded) {
                    const newOrderProducts = [...orderProducts];
                    newOrderProducts[indexForDiscount] = {
                      ...newOrderProducts[indexForDiscount],
                      hasDiscount: true,
                      netDiscount: discount,
                    };

                    setOrderProducts(newOrderProducts);
                  } else {
                    const newOrderProducts = [...orderProducts];
                    newOrderProducts[indexForDiscount].hasDiscount = true;
                    newOrderProducts[indexForDiscount].grossDiscount = discount;
                    setOrderProducts(newOrderProducts);
                  }
                } else {
                  const newOrderProducts = [...orderProducts];
                  if (tenants[selectedTenant].settings.taxIncluded) {
                    newOrderProducts.push({
                      price: {
                        netPrice: 0,
                        grossPrice: 0,
                        priceCurrency:
                          tenants[selectedTenant].settings.priceCurrency,
                      },
                      quantity: 1,
                      hasDiscount: true,
                      netDiscount: discount,
                    });
                  } else {
                    newOrderProducts.push({
                      price: {
                        netPrice: 0,
                        grossPrice: 0,
                        priceCurrency:
                          tenants[selectedTenant].settings.priceCurrency,
                      },
                      quantity: 1,
                      hasDiscount: true,
                      grossDiscount: discount,
                    });
                  }
                  setOrderProducts(newOrderProducts);
                }
                setOpenSetDiscount(false);
                setDiscount("");
                setIndexForDiscount(null);
              }}
            >
              {translation.saveButton}
            </Button>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};

export default EditOrderProducts;
