import { useMemo, useCallback, useState } from "react";
import { useField } from "formik";
import { useDropzone } from "react-dropzone";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import { AddCircleOutlined } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Stack, Typography } from "@mui/material";
import useTranslation from "components/customHooks/translations";
import AddImageUrl from "../AddImageUrl";
import { isValidHttpUrl } from "helpers";
import { useSnackbarContext } from "components/contexts/SnackbarContext";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderWidth: 2,
  borderRadius: 8,
  padding: "16px 16px",
  borderColor: "#BEBEBE",
  borderStyle: "dashed",
  backgroundColor: "#FFF",
  color: "#000",
  fontSize: "14px",
  fontWeight: 500,
  outline: "none",
  transition: "border .24s ease-in-out",

  height: "100%",
  width: "100%",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const DropZoneBrandField = ({
  label,
  labelDescription,
  name,
}: {
  label?: string;
  labelDescription?: string;
  name: string;
}) => {
  const [field, meta, helper] = useField(name);
  const [showImageUrlForm, setShowImageUrlForm] = useState(false);
  const { setErrorMessage } = useSnackbarContext();
  const translation = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles.forEach((file: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          helper.setValue({
            contentUrl: reader.result,
          });
        };
      });
    },
    [field.value, helper]
  );

  const {
    open,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/svg+xml": [".svg"],
      "image/webp": [".webp"],
    },
  });

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );

  const isValid = !meta.error;
  const isInvalid = meta.touched && !isValid;
  return (
    <>
      <div {...getRootProps({ style })}>
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          {field.value == null && (
            <Box>
              <IconButton color="primary" onClick={open} sx={{ p: 2 }}>
                <AddCircleOutlined
                  sx={{ fontSize: "40px" }}
                ></AddCircleOutlined>
              </IconButton>
            </Box>
          )}
          <Box flexGrow={1}>
            <Stack spacing={1}>
              <input {...getInputProps()} />
              <Typography>{label}</Typography>
              {field.value && (
                <Box display="flex" justifyContent={"center"}>
                  <Card
                    sx={{
                      maxWidth: 200,
                      margin: 2,
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="140"
                      sx={{ backgroundSize: "contain", objectFit: "contain" }}
                      image={field.value.contentUrl}
                      alt="furniture"
                    />{" "}
                  </Card>
                </Box>
              )}
              <Typography
                sx={{
                  color: "text.secondary",
                }}
              >
                {labelDescription}
              </Typography>
              {field.value && (
                <Box display="flex" justifyContent={"flex-end"}>
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                  >
                    <Button onClick={open}>Change</Button>
                    <Button
                      onClick={() => {
                        helper.setValue(null);
                      }}
                    >
                      Remove
                    </Button>
                  </ButtonGroup>
                </Box>
              )}
            </Stack>
          </Box>
        </Stack>
      </div>
      <Box sx={{ mt: 2 }}>
        <Button
          variant="outlined"
          onClick={() => {
            setShowImageUrlForm(true);
          }}
        >
          {translation.products.uploadImageUrl}
        </Button>
      </Box>
      <AddImageUrl
        handleClose={() => setShowImageUrlForm(false)}
        multiple={false}
        title={label}
        handleSubmitUrls={(imageUrls: any) => {
          const imageUrl = imageUrls[0];

          helper.setValue({
            contentUrl: imageUrl,
          });
          setShowImageUrlForm(false);
        }}
        showForm={showImageUrlForm}
      ></AddImageUrl>
    </>
  );
};

export default DropZoneBrandField;
