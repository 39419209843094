import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import {
  getAppsByTenantId,
  getAppDeploymentStatus,
  deployApp,
  updateApp,
} from "services/tenants";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  deleteDnsRecord,
  getDnsZonesByTenant,
  newDnsRecord,
} from "services/dns";
import { DeleteOutline, Launch, MoreVert } from "@mui/icons-material";
import { IDnsRecord, IDnsZone } from "interfaces";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { routeNames } from "routes";
import { useNavigate } from "react-router-dom";
import InfoPopover from "components/InfoPopover";
import { LoadingButton } from "@mui/lab";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage } from "helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import FormTextField from "components/Form/FormTextField/FormTextField";

const Application = () => {
  const { selectedTenant, tenants, updateTrigger, setUpdateTrigger } =
    useUserContext();
  const { setLoading } = useLoadingContext();
  const t = useTranslation();
  const [tenant, setTenant] = useState<any>(null);
  const [domains, setDomains] = useState<IDnsZone[]>([]);
  const [appRecords, setAppRecords] = useState<IDnsRecord[]>([]);
  const [appDeploymentStatusIsLoading, setAppDeploymentStatusIsLoading] =
    useState(false);
  const [app, setApp] = useState<any>(null);
  const [domainsLoading, setDomainsLoading] = useState(true);
  const [appDeployed, setAppDeployed] = useState(false);
  const [formError, setFormError] = React.useState("");
  const [aliasToDelete, setAliasToDelete] = useState<IDnsRecord | null>(null);
  const [addAliasError, setAddAliasError] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);
  const [newAliasSubdomain, setNewAliasSubdomain] = useState("");
  const [newAliasDomain, setNewAliasDomain] = useState("");
  const [openAddAlias, setOpenAddAlias] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpenConfirmation(true);
    setAnchorEl(null);
  };

  const translation = useTranslation();
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const navigate = useNavigate();

  const appSchema = Yup.object().shape({
    helpEmail: Yup.string()
      .email(translation.signUpEmailError)

      .required(translation.required),
  });

  useEffect(() => {
    if (tenants) {
      setTenant(tenants[selectedTenant]);
    }
  }, [selectedTenant, tenants]);

  useEffect(() => {
    setLoading(true);
    if (tenants) {
      setAppRecords([]);
      getAppsByTenantId(tenants[selectedTenant].id)
        .then((res) => {
          setApp(res.data["hydra:member"][0]);
          if (res.data["hydra:member"][0].status == "deployed") {
            setAppDeployed(true);
          }
          setLoading(false);
        })
        .catch((e) => setErrorMessage(errorMessage(e)));
      setDomainsLoading(true);
      getDnsZonesByTenant(tenants[selectedTenant].id).then((res) => {
        setDomains(res.data["hydra:member"]);
        const records: IDnsRecord[] = [];
        res.data["hydra:member"].forEach((domain: IDnsZone) => {
          if (domain["@id"]) {
            setNewAliasDomain(domain["@id"]);
          }
          domain.dnsRecords?.forEach((dnsRecord) => {
            if (dnsRecord.serviceType == "app") {
              records.push(dnsRecord);
            }
          });
        });
        setAppRecords(records);
        setDomainsLoading(false);
      });
    }
  }, [selectedTenant, tenants, updateTrigger]);

  useEffect(() => {
    setAppDeploymentStatusIsLoading(true);
    if (app !== null) {
      if (app.status !== "deployed") {
        const checkInterval = setInterval(() => {
          getAppsByTenantId(tenants[selectedTenant].id)
            .then((res) => {
              setApp(res.data["hydra:member"][0]);
              if (res.data["hydra:member"][0].status == "deployed") {
                setAppDeployed(true);
              }
              setLoading(false);
            })
            .catch((e) => setErrorMessage(errorMessage(e)));
          getAppDeploymentStatus(app.id)
            .then((res) => {
              if (res.data.done === true) {
                setAppDeployed(true);
                setApp({ ...app, status: "deployed" });
                clearInterval(checkInterval);
              }
            })
            .catch((e) => setErrorMessage(errorMessage(e)));
        }, 30000);
        return function cleanup() {
          clearInterval(checkInterval);
        };
      }
    }
  }, [app]);

  const handleCloseAddAlias = () => {
    setOpenAddAlias(false);
  };

  const handleAddAlias = () => {
    if (newAliasSubdomain === "") {
      setAddAliasError("required");
    } else {
      //let alreadyExists = false;

      // for (let i = 0; i < appRecords.length; i++) {
      //   if (
      //     newAliasSubdomain === appRecords[0].name &&
      //     newAliasDomain === appRecords[0].dnsZone["@id"]
      //   ) {
      //     setAddAliasError("Record already exists");
      //     return;
      //   }
      // }
      // setRequestLoading(true);
      const dnsRecord: IDnsRecord = {
        name: newAliasSubdomain,
        serviceType: "app",
        dnsZone: newAliasDomain,
        tenant: "/tenants/" + tenants[selectedTenant].id,
      };
      setRequestLoading(true);
      newDnsRecord(dnsRecord)
        .then((response) => {
          setRequestLoading(false);
          setSuccessMessage(translation.savedMessage);
          setUpdateTrigger(!updateTrigger);
          handleCloseAddAlias();
        })
        .catch((e) => {
          setRequestLoading(false);
          setErrorMessage(errorMessage(e));
          handleCloseAddAlias();
        });
    }
  };

  const deployApplication = () => {
    if (app) {
      setUpdateLoading(true);
      deployApp(app.id)
        .then((response) => {
          setSuccessMessage(t.app.updateStarted);
          setUpdateLoading(false);
          setUpdateTrigger(!updateTrigger);
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setUpdateLoading(false);
        });
    }
  };

  const handleFastAndSimpleCustomDomains = async () => {
    setLoading(true);
    setOpenAddAlias(false);
    for (let i = 0; i < domains.length; i++) {
      try {
        const dnsRecord: IDnsRecord = {
          name: "@",
          serviceType: "app",
          dnsZone: domains[i]["@id"],
          tenant: "/tenants/" + tenants[selectedTenant].id,
        };
        await newDnsRecord(dnsRecord);
        const dnsRecordWww: IDnsRecord = {
          name: "www",
          serviceType: "app",
          dnsZone: domains[i]["@id"],
          tenant: "/tenants/" + tenants[selectedTenant].id,
        };
        await newDnsRecord(dnsRecordWww);
      } catch (e) {
        setErrorMessage(errorMessage(e));
        return;
      }
    }
    setLoading(false);
    setSuccessMessage(t.savedMessage);
    setUpdateTrigger(!updateTrigger);
  };

  const handleDeleteAlias = () => {
    if (aliasToDelete) {
      setRequestLoading(true);
      deleteDnsRecord(aliasToDelete)
        .then((res) => {
          setSuccessMessage(translation.deletedMessage);
          setUpdateTrigger(!updateTrigger);
          setOpenConfirmation(false);
          setRequestLoading(false);
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setOpenConfirmation(false);
          setRequestLoading(false);
        });
    }
  };

  return (
    <>
      <Paper sx={{ p: 2 }}>
        {app != null && domainsLoading == false && (
          <Stack spacing={2}>
            <Typography variant="h5">{t.app.domain}</Typography>

            {appRecords.length == 0 && (
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography variant={"body2"}>{app.domain}</Typography>
                <IconButton
                  size={"small"}
                  color="primary"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    const url = "https://" + app.domain;
                    window.open(url, "_blank");
                  }}
                >
                  <Launch fontSize="small" />
                </IconButton>
              </Stack>
            )}
            {appRecords.length == 0 && (
              <Stack spacing={1}>
                <Typography>{t.app.customDomains}</Typography>
              </Stack>
            )}
            {domains.length == 0 && (
              <Box>
                <Button
                  variant="outlined"
                  color={"error"}
                  onClick={() => {
                    navigate(routeNames.infrastructure);
                  }}
                >
                  {translation.app.setDomains}
                </Button>
              </Box>
            )}
            <Stack>
              {appRecords.length == 0 && !domainsLoading && (
                <Typography variant="caption">
                  {t.app.noCustomDomains}
                </Typography>
              )}
              {appRecords.map((record) => (
                <Stack
                  direction={"row"}
                  key={"record-" + record["@id"]}
                  spacing={1}
                  alignItems={"center"}
                >
                  <Typography>{record.fqdn}</Typography>
                  <IconButton
                    size={"small"}
                    color="primary"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      const url = "https://" + record.fqdn;
                      window.open(url, "_blank");
                    }}
                  >
                    <Launch fontSize="small" />
                  </IconButton>
                  <IconButton
                    size={"small"}
                    onClick={(e) => {
                      handleClick(e);
                      setAliasToDelete(record);
                    }}
                  >
                    <MoreVert></MoreVert>
                  </IconButton>
                </Stack>
              ))}
            </Stack>
            {domains.length > 0 && (
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenAddAlias(true);
                  }}
                >
                  {translation.app.addCustomDomain}
                </Button>
              </Box>
            )}

            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography variant="h6">
                Status: {appDeployed ? "Deployed" : <CircularProgress />}
              </Typography>
              <LoadingButton
                onClick={() => {
                  deployApplication();
                }}
                loading={updateLoading}
                variant="outlined"
              >
                {t.app.deployApplication}
              </LoadingButton>
            </Stack>
            <Divider />
            <Formik
              initialValues={{
                helpEmail: app.helpEmail ? app.helpEmail : "",
              }}
              validationSchema={appSchema}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                updateApp({
                  ...app,
                  helpEmail: values.helpEmail,
                })
                  .then(() => {
                    setSubmitting(false);

                    setSuccessMessage(translation.savedMessage);
                    setUpdateTrigger(!updateTrigger);
                  })
                  .catch((e) => {
                    setErrorMessage(errorMessage(e));
                    setSubmitting(false);
                  });
              }}
            >
              {({ handleSubmit, isSubmitting, values }) => (
                <form noValidate className="" onSubmit={handleSubmit}>
                  <Box>
                    <Stack spacing={3}>
                      <Box>
                        <FormTextField
                          label={translation.app.helpEmailLabel}
                          name="helpEmail"
                        />
                      </Box>

                      <Box>
                        <LoadingButton
                          variant="contained"
                          type="submit"
                          loading={isSubmitting}
                        >
                          {translation.saveButton}
                        </LoadingButton>
                      </Box>
                    </Stack>
                  </Box>
                </form>
              )}
            </Formik>
          </Stack>
        )}
      </Paper>
      <Dialog onClose={handleCloseAddAlias} open={openAddAlias} fullWidth>
        <DialogTitle>{t.app.addCustomDomain}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} alignItems={"center"}>
            {appRecords.length == 0 && (
              <>
                <Stack alignItems={"center"}>
                  <Box>
                    <InfoPopover
                      infoContent={t.app.simpleSetupDescription}
                    ></InfoPopover>
                  </Box>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleFastAndSimpleCustomDomains();
                    }}
                  >
                    {t.app.simpleSetup}
                  </Button>
                </Stack>
                <Typography variant="h5">{t.or}</Typography>
              </>
            )}
            <Stack direction={"row"} sx={{ pt: 1 }} spacing={1}>
              <Box>
                <TextField
                  label={t.app.subdomain}
                  error={!!addAliasError}
                  helperText={addAliasError}
                  onChange={(event) => {
                    setNewAliasSubdomain(event.target.value);
                  }}
                ></TextField>
              </Box>
              <Box>
                <Select
                  sx={{ minWidth: 250 }}
                  value={newAliasDomain}
                  onChange={(event: SelectChangeEvent) => {
                    setNewAliasDomain(event.target.value as string);
                  }}
                >
                  {domains.map((domain) => (
                    <MenuItem
                      value={domain["@id"]}
                      key={"select-" + domain["@id"]}
                    >
                      {domain.domainName}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <InfoPopover infoContent={t.app.subdomainInfoHelp}></InfoPopover>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseAddAlias}>
            {translation.closeButton}
          </Button>
          <LoadingButton
            loading={requestLoading}
            variant="contained"
            onClick={handleAddAlias}
          >
            {translation.saveButton}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setOpenConfirmation(false)}
        open={openConfirmation}
        fullWidth
      >
        <DialogTitle>{t.app.deleteRecord}</DialogTitle>
        <DialogContent>
          {aliasToDelete && (
            <Stack spacing={2}>
              <Typography>{t.app.deleteRecordMessage}</Typography>
              <Typography color={"primary.dark"}>
                {aliasToDelete.fqdn}
              </Typography>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpenConfirmation(false)}>
            {translation.closeButton}
          </Button>

          <LoadingButton
            loading={requestLoading}
            variant="contained"
            onClick={handleDeleteAlias}
            color="warning"
          >
            {translation.deleteButton}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>
          <DeleteOutline /> Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default Application;
