import { useMemo, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Chip,
  Drawer,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useTranslation from "components/customHooks/translations";
import {
  AddCircleOutlined,
  Check,
  Delete,
  DragIndicator,
  Edit,
} from "@mui/icons-material";
import {
  DndContext,
  closestCenter,
  MouseSensor as LibMouseSensor,
  KeyboardSensor as LibKeyboardSensor,
  TouchSensor as LibTouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { Grid } from "./Grid";
import { SortablePhoto } from "./SortablePhoto";
import { Photo } from "./Photo";
import { formDrawerWidth } from "../constants";
import { isValidHttpUrl, stringToHash } from "helpers";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import AddImageUrl from "../AddImageUrl";

export class MouseSensor extends LibMouseSensor {
  static activators = [
    {
      eventName: "onMouseDown",
      handler: ({ nativeEvent: event }) => {
        if (event.button === 0) {
          return shouldHandleEvent(event.target);
        } else {
          return false;
        }
      },
    },
  ];
}

export class KeyboardSensor extends LibKeyboardSensor {
  static activators = [
    {
      eventName: "onKeyDown",
      handler: ({ nativeEvent: event }) => {
        return shouldHandleEvent(event.target);
      },
    },
  ];
}

export class TouchSensor extends LibTouchSensor {
  static activators = [
    {
      eventName: "onTouchStart",
      handler: ({ nativeEvent: event }) => {
        return shouldHandleEvent(event.target);
      },
    },
  ];
}

function shouldHandleEvent(element) {
  let cur = element;

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false;
    }
    cur = cur.parentElement;
  }

  return true;
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderWidth: 2,
  borderRadius: 8,
  padding: "16px 16px",
  borderColor: "#BEBEBE",
  borderStyle: "dashed",
  backgroundColor: "#FFF",
  color: "#000",
  fontSize: "14px",
  fontWeight: 500,
  outline: "none",
  transition: "border .24s ease-in-out",
  height: "100%",
  width: "100%",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const DropZoneBulkProductImg = ({
  label,
  labelDescription,
  uploadedProduct,
  setNewUploadedProductImages,
  productIndex,
  productName = "",
}) => {
  const [newAlt, setNewAlt] = useState("");
  const [contextImageIndex, setContextImageIndex] = useState(null);
  const { setErrorMessage } = useSnackbarContext();
  const [contextAnchorPosition, setContextAnchorPosition] = useState({
    top: 0,
    left: 0,
  });
  const [showButtons, setShowButtons] = useState(null);
  const [showForm, setShowForm] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [activeUrl, setActiveUrl] = useState(null);
  const [showImageUrlForm, setShowImageUrlForm] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor),
    useSensor(TouchSensor)
  );
  const translation = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles) => {
      let images = JSON.parse(JSON.stringify(uploadedProduct.images));
      acceptedFiles.forEach((file) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function () {
          let indeximage = images.findIndex(
            (image) => image.contentUrl === reader.result
          );

          if (indeximage === -1) {
            images.push({
              contentUrl: reader.result,
              priority: images.length,
            });
          }

          setNewUploadedProductImages(images, productIndex);
        };
      });
    },
    [uploadedProduct]
  );

  const {
    open,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/svg+xml": [".svg"],
      "image/webp": [".webp"],
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );

  function handleDragStart(event) {
    setActiveId(event.active.id);
    uploadedProduct.images.forEach((image) => {
      if (stringToHash(image.contentUrl) === event.active.id) {
        setActiveUrl(image.contentUrl);
      }
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      let newIndex = uploadedProduct.images.findIndex(
        (image) => stringToHash(image.contentUrl) === over.id
      );

      let oldIndex = uploadedProduct.images.findIndex(
        (image) => stringToHash(image.contentUrl) === active.id
      );
      let newArray = arrayMove(uploadedProduct.images, oldIndex, newIndex);
      newArray = newArray.map((image, index) => {
        return {
          ...image,
          priority: index,
        };
      });
      setNewUploadedProductImages(newArray, productIndex);
    }
    setActiveId(null);
    setShowButtons(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  function handleChangeMainImage() {
    let newArray = arrayMove(uploadedProduct.images, contextImageIndex, 0);
    newArray = newArray.map((image, index) => {
      return {
        ...image,
        priority: index,
      };
    });

    setNewUploadedProductImages(newArray, productIndex);
    handleContextClose();
  }

  function handleContextClose() {
    setContextImageIndex(null);
    setContextAnchorPosition({ top: 0, left: 0 });
  }

  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <div {...getRootProps({ style })}>
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box>
              <IconButton color="primary" sx={{ p: 2 }}>
                <AddCircleOutlined
                  sx={{ fontSize: "40px" }}
                ></AddCircleOutlined>
              </IconButton>
            </Box>
            <input {...getInputProps()} />
            <Stack spacing={1}>
              <input {...getInputProps()} />
              <Typography>{label}</Typography>

              <Typography
                sx={{
                  color: "text.secondary",
                }}
              >
                {labelDescription}
              </Typography>
            </Stack>
          </Stack>
        </div>
        <Box>
          <Button
            variant="outlined"
            onClick={() => {
              setShowImageUrlForm(true);
            }}
          >
            {translation.products.uploadImageUrl}
          </Button>
        </Box>
        <Box>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
          >
            <SortableContext
              items={uploadedProduct.images.map((image) =>
                stringToHash(image.contentUrl)
              )}
              strategy={rectSortingStrategy}
            >
              <Grid columns={5}>
                {uploadedProduct.images.map((file, index) => (
                  <SortablePhoto
                    key={stringToHash(file.contentUrl)}
                    url={file.contentUrl}
                    onContextMenu={(e) => {
                      e.preventDefault(); // prevent the default behaviour when right clicked

                      setContextImageIndex(index);
                      setContextAnchorPosition({
                        top: e.clientY,
                        left: e.clientX,
                      });
                    }}
                    onMouseEnter={() => setShowButtons(index)}
                    onMouseLeave={() => setShowButtons(null)}
                    index={index}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {showButtons === index && (
                        <Stack direction="row" justifyContent="space-between">
                          <Box sx={{ p: 1 }}>
                            <DragIndicator color="primary"></DragIndicator>
                          </Box>
                          <Box data-no-dnd="true">
                            <IconButton
                              aria-label="delete"
                              data-no-dnd="true"
                              onClick={() => {
                                let newImages = JSON.parse(
                                  JSON.stringify(uploadedProduct.images)
                                );
                                newImages.splice(index, 1);
                                setNewUploadedProductImages(
                                  newImages,
                                  productIndex
                                );
                              }}
                            >
                              <Delete color="warning"></Delete>
                            </IconButton>
                            <IconButton
                              aria-label="edit"
                              onClick={() => {
                                setNewAlt(
                                  file.caption !== undefined ? file.caption : ""
                                );
                                setShowForm(index);
                              }}
                            >
                              <Edit color="primary"></Edit>
                            </IconButton>
                          </Box>
                        </Stack>
                      )}
                      {index === 0 && (
                        <Box
                          sx={{
                            position: "absolute",
                            right: 4,
                            bottom: 4,
                          }}
                        >
                          <Chip
                            color="success"
                            icon={<Check />}
                            label={"Main photo"}
                          />
                        </Box>
                      )}
                    </Box>
                  </SortablePhoto>
                ))}
              </Grid>
            </SortableContext>
            <DragOverlay adjustScale={true}>
              {activeId ? (
                <Photo
                  url={activeUrl}
                  index={uploadedProduct.images.findIndex(
                    (image) => stringToHash(image.contentUrl) === activeId
                  )}
                  isOverlay={true}
                />
              ) : null}
            </DragOverlay>
          </DndContext>
        </Box>
      </Stack>
      <Menu
        id="image-context-menu"
        anchorReference="anchorPosition"
        onContextMenu={(e) => {
          e.preventDefault();
          handleContextClose();
        }}
        anchorPosition={contextAnchorPosition}
        open={contextImageIndex !== null}
        onClose={handleContextClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => handleChangeMainImage()}>
          {translation.products.setMainImage}
        </MenuItem>
      </Menu>
      <Drawer
        anchor="right"
        open={showForm !== null}
        onClose={() => {
          setShowForm(null);
        }}
        sx={{
          "& .MuiDrawer-paper": { width: formDrawerWidth },
        }}
      >
        <Stack spacing={2} sx={{ p: 2 }}>
          <Typography variant="h5">{translation.products.editImage}</Typography>
          {showForm !== null && (
            <Card sx={{ width: 200 }}>
              <CardMedia
                component="img"
                height="200"
                image={uploadedProduct.images[showForm].contentUrl}
              ></CardMedia>
            </Card>
          )}
          {showForm !== null && (
            <TextField
              variant="standard"
              value={newAlt}
              label={translation.products.imageAltLabel}
              onChange={(event) => setNewAlt(event.target.value)}
            ></TextField>
          )}
          <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
            <Button
              variant={"outlined"}
              onClick={() => {
                setShowForm(null);
              }}
            >
              {translation.closeButton}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                let newValues = uploadedProduct.images.map((image, index) => {
                  if (showForm === index) {
                    return {
                      ...image,
                      caption: newAlt,
                    };
                  } else return image;
                });
                setNewUploadedProductImages(newValues, productIndex);

                setShowForm(null);
              }}
            >
              {translation.saveButton}
            </Button>
          </Stack>
        </Stack>
      </Drawer>
      <AddImageUrl
        handleClose={() => setShowImageUrlForm(false)}
        multiple={true}
        title={productName}
        handleSubmitUrls={(imageUrls) => {
          let images = [...uploadedProduct.images];
          imageUrls.forEach((imageUrl) => {
            if (!isValidHttpUrl(imageUrl)) {
              setErrorMessage(translation.products.invalidUrls);
            } else {
              let indeximage = uploadedProduct.images.findIndex(
                (image) => image.contentUrl === imageUrl
              );
              if (indeximage === -1) {
                images.push({
                  contentUrl: imageUrl,
                  priority: images.length,
                });
              }
            }
          });
          setNewUploadedProductImages(images, productIndex);
          setShowImageUrlForm(false);
        }}
        showForm={showImageUrlForm}
      ></AddImageUrl>
    </>
  );
};

export default DropZoneBulkProductImg;
