import { useState, useEffect } from "react";
import { useUserContext } from "components/contexts/UserContext";
import { ITax } from "interfaces";
import { getSettingsByTenant } from "services/tenants";

export default function useSettings() {
  const [settings, setSettings] = useState<ITax[]>([]);
  const [updateSettingsTrigger, setUpdateSettingsTrigger] = useState(false);

  const [settingsLoading, setSettingsLoading] = useState(true);

  const { selectedTenant, tenants } = useUserContext();

  useEffect(() => {
    setSettingsLoading(true);
    if (tenants !== null) {
      if (tenants[selectedTenant]) {
        getSettingsByTenant(tenants[selectedTenant].id)
          .then((res) => {
            if (res.data["hydra:member"].length > 0) {
              setSettings(res.data["hydra:member"][0]);
              setSettingsLoading(false);
            }
            setSettingsLoading(false);
          })
          .catch((e) => {
            setSettingsLoading(false);
          });
      }
    }
  }, [selectedTenant, tenants, updateSettingsTrigger]);

  return {
    settings,
    updateSettingsTrigger,
    setUpdateSettingsTrigger,
    settingsLoading,
    setSettingsLoading,
  };
}
