import React, { useMemo, useState } from "react";
import { useField } from "formik";
import ReactQuill, { Quill } from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { companyData } from "helpers/dynamicData";
import useTranslation from "components/customHooks/translations";
import { createImage, getPolicyTemplates } from "services/tenants";

import ImageUploader from "helpers/quill-image-uploader/dist";

import { errorMessage } from "helpers";
import { useTenantContext } from "components/contexts/TenantContext";
import { useUserContext } from "components/contexts/UserContext";

const BlockEmbed = Quill.import("blots/block/embed");

class ImageBlot extends BlockEmbed {
  static create(value) {
    const node = super.create();
    if (typeof value === "string") {
      node.setAttribute("src", value);
    } else {
      node.setAttribute("src", value.url);
    }
    node.setAttribute("style", "max-width: 100%; height: auto;");
    return node;
  }

  static value(node) {
    return {
      alt: node.getAttribute("alt"),
      url: node.getAttribute("src"),
    };
  }
}
ImageBlot.blotName = "image";
ImageBlot.tagName = "img";

Quill.register(ImageBlot, false);

Quill.register("modules/imageUploader", ImageUploader, false);

class Video extends BlockEmbed {
  static create(value) {
    let node = super.create(value);
    let iframe = document.createElement("iframe");
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", true);
    iframe.setAttribute("style", "width: 560px; height: 315px;");
    iframe.setAttribute("src", value);
    node.appendChild(iframe);
    return node;
  }

  static value(domNode) {
    return domNode.firstChild.getAttribute("src");
  }
}
Video.blotName = "video";
Video.className = "ql-video";
Video.tagName = "div";

Quill.register(
  {
    "formats/video": Video,
  },
  false
);

const getModules = () => {
  return {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike"],
      ["link", "blockquote", "code"],
      [
        {
          list: "ordered",
        },
        {
          list: "bullet",
        },
        { align: [] },
      ],
      ["image", "video"],
    ],
    imageUploader: {
      upload: (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            createImage({
              contentUrl: reader.result,
            })
              .then((response) => {
                resolve(response.data.contentUrl);
              })
              .catch((e) => {
                reject(errorMessage(e));
              });
          };
        });
      },
    },
  };
};

const QuillSnippetField = ({ name, label = "" }) => {
  const [quillRef, setQuillRef] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [searchFilter, setSearchFilter] = useState("");
  const [openTemplates, setOpenTemplates] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { selectedTenant, tenants } = useUserContext();
  const [field, meta, helper] = useField(name);
  const isValid = !meta.error;
  const isInvalid = meta.touched && !isValid;
  const translation = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSearchFilter("");
    if (quillRef === null) return;
    const quillEditor = quillRef.getEditor();
    const range = quillEditor.getSelection();
    const position = range ? range.index : 0;
    setCursorPosition(position);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenTemplates = () => {
    getPolicyTemplates().then((res) => {
      if (
        res.data[tenants[selectedTenant].settings.storeLocale] !== undefined
      ) {
        setTemplates(res.data[tenants[selectedTenant].settings.storeLocale]);
      } else {
        setTemplates(res.data["en-US"]);
      }
    });
    setOpenTemplates(true);
  };
  const handleCloseTemplates = () => {
    setOpenTemplates(false);
  };

  const open = Boolean(anchorEl);

  const handleInsert = (text) => {
    handleClose();
    if (quillRef === null) return;
    if (typeof quillRef.getEditor !== "function") return;
    const quillEditor = quillRef.getEditor();
    const range = quillEditor.getSelection();
    let position = range ? range.index : 0;
    const textToInsert = "{{" + text + "}}";
    if (cursorPosition !== 0) {
      position = cursorPosition;
    }
    quillEditor.insertText(position, textToInsert);
    quillEditor.focus();
    quillEditor.setSelection(position + textToInsert.length, 0);
    setCursorPosition(0);
  };

  const handleListItemClick = (template) => {
    helper.setValue(template.content);
    handleCloseTemplates();
  };

  const modules = useMemo(() => getModules(), []);

  return (
    <>
      <Box display="flex" justifyContent={"space-between"}>
        <Button variant="outlined" onClick={handleOpenTemplates}>
          {translation.policies.generateFromTemplate}
        </Button>
        <Button variant="contained" onClick={handleClick}>
          {translation.policies.dynamicData}
        </Button>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        autoFocus={false}
        onKeyDown={(e) => e.stopPropagation()}
        disableAutoFocusItem
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          disableRipple
          sx={{ p: 2 }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <TextField
            variant="standard"
            label={translation.policies.searchData}
            onChange={(event) => {
              setSearchFilter(event.target.value);
            }}
          ></TextField>
        </MenuItem>
        {companyData
          .filter((data) =>
            searchFilter === ""
              ? true
              : data.label.toLowerCase().includes(searchFilter.toLowerCase())
          )
          .map((field, index) => {
            return (
              <MenuItem
                key={"menu-snipped-" + index}
                onClick={() => {
                  handleInsert(field.field);
                  handleClose();
                }}
              >
                {field.label}
              </MenuItem>
            );
          })}
        <MenuItem
          onClick={() => {
            handleInsert("domain");
            handleClose();
          }}
        >
          {translation.dnsZone.domainName}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleInsert("updatedAt");
            handleClose();
          }}
        >
          {translation.policies.updatedAt}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleInsert("contactEmail");
            handleClose();
          }}
        >
          {translation.policies.contactEmail}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleInsert("supportEmail");
            handleClose();
          }}
        >
          {translation.policies.supportEmail}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleInsert("supportPhoneNumber");
            handleClose();
          }}
        >
          {translation.policies.supportPhoneNumber}
        </MenuItem>
      </Menu>

      <ReactQuill
        ref={(el) => setQuillRef(el)}
        id={field.name}
        onChange={(value) => {
          helper.setValue(value);
        }}
        value={field.value}
        theme="snow"
        bounds={`.ql-container`}
        modules={modules}
      />
      <Dialog onClose={handleCloseTemplates} open={openTemplates}>
        <DialogTitle>{translation.policies.generateFromTemplate}</DialogTitle>
        <DialogContent>
          <Typography fontSize={12} color={"warning.main"}>
            {translation.possibleDataLossWarning}
          </Typography>
          <List sx={{ pt: 0 }}>
            {templates &&
              templates.map((template) => (
                <ListItem key={template.url} disableGutters>
                  <ListItemButton onClick={() => handleListItemClick(template)}>
                    <ListItemText primary={template.title} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QuillSnippetField;
