import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import LoadingIcon from "components/Feedback/LoadingIcon";
import { ReactComponent as FastForward } from "assets/img/fast-forward.svg";
import {
  editOrderStatus,
  getOrder,
  createAwb,
  createInvoice,
  getInvoice,
  getInvoiceDownload,
  updateOrder,
  editOrder,
} from "services/orders";
import {
  Grid,
  Box,
  Typography,
  Stack,
  Button,
  ToggleButton,
  Link,
  Modal,
  Dialog,
  Drawer,
  Toolbar,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Menu,
  MenuProps,
  SvgIcon,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useTranslation from "components/customHooks/translations";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { orderStatuses } from "helpers/orderStatuses";
import PageHeader from "components/PageHeader";
import OrderProductsList from "components/Orders/OrderProductsList";
import { Done } from "@mui/icons-material";
import { useUserContext } from "components/contexts/UserContext";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { errorMessage, formattedDate } from "helpers";
import {
  IAddress,
  IInvoice,
  IOrder,
  IOrderProduct,
  IOrderView,
} from "interfaces";
import AddressCard from "components/Customers/AddressCard";
import AddressForm from "pages/Customers/AddressForm";
import { useLoadingContext } from "components/contexts/LoadingContext";
import EditProducts from "pages/Products/EditProducts";
import EditOrderProducts from "components/Orders/EditProducts";
import { drawerWidth } from "components/Layout/SideNav";
import { routeNames } from "routes";
import { Link as RouterLink } from "react-router-dom";

export async function loader({ params }: any) {
  return getOrder(params.orderId);
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const OrderView = () => {
  const { selectedTenant, tenants } = useUserContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const { loading, setLoading } = useLoadingContext();
  const [showNewAddress, setShowNewAddress] = useState(false);

  const [order, setOrder] = useState<IOrderView | null>(null);
  const [orderProducts, setOrderProducts] = useState<IOrderProduct[]>([]);
  const [orderisLoading, setOrderisLoading] = useState(true);
  const [awb, setAwb] = useState<any>(null);
  const [invoice, setInvoice] = useState<IInvoice | null>(null);
  const [orderStatus, setOrderStatus] = useState<any>(0);
  const [successStatus, setSuccessStatus] = useState(false);
  const [openEditProducts, setOpenEditProducts] = useState(false);
  const [openEditProductsConfirmation, setOpenEditProductsConfirmation] =
    useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [openNewStatus, setOpenNewStatus] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState<IAddress | null>(null);
  const [loadingAwb, setLoadingAwb] = useState(false);
  const [newCoupon, setNewCoupon] = useState("");
  const [openApplyCoupon, setOpenApplyCoupon] = useState(false);
  const [awbError, setAwbError] = useState("");
  const translation = useTranslation();
  const { orderId } = useParams();

  const [anchorElAdvanced, setAnchorElAdvanced] =
    React.useState<null | HTMLElement>(null);
  const openAdvanced = Boolean(anchorElAdvanced);
  const handleClickAdvanced = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAdvanced(event.currentTarget);
  };
  const handleCloseAdvanced = () => {
    setAnchorElAdvanced(null);
  };

  useEffect(() => {
    if (orderId !== undefined) {
      setLoading(true);
      getOrder(orderId)
        .then((order) => {
          if (order.data) {
            if (order.data.awbs.length > 0) {
              setAwb(order.data.awbs[0]);
            }
            setOrder(order.data);
            const newOrderProducts = order.data.products.map(
              (orderProduct: IOrderProduct) => {
                return {
                  ...orderProduct,
                };
              }
            );
            setOrderProducts(newOrderProducts);
            setLoading(false);
            if (order.data.invoices.length > 0) {
              setLoadingAwb(true);
              getInvoice(order.data.invoices[0]).then((res) => {
                setLoadingAwb(false);
                setInvoice(res.data);
              });
            }
            setOrderisLoading(false);
          }
        })
        .catch((e) => setErrorMessage(errorMessage(e)));
    }
  }, [updateTrigger]);

  useEffect(() => {
    if (order) {
      orderStatuses.forEach((status, index) => {
        if (status.slug === order.status) {
          setOrderStatus(index);
        }
      });
    }
  }, [order]);

  const handleOpenChangeStatus = (status: string) => {
    setNewStatus(status);
    setOpenNewStatus(true);
  };

  const handleApplyCoupon = () => {
    if (newCoupon && order) {
      setLoading(true);
      editOrder({ ...order, coupons: undefined, couponCode: newCoupon })
        .then((res) => {
          setSuccessMessage(translation.savedMessage);
          setNewCoupon("");
          setOpenApplyCoupon(false);
          setUpdateTrigger(!updateTrigger);
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setLoadingStatus(false);
          if (order) {
            orderStatuses.forEach((status, index) => {
              if (status.slug === order.status) {
                setOrderStatus(index);
              }
            });
          }
        });
    }
  };

  const handleCloseApplyCoupon = () => {
    setOpenApplyCoupon(false);
    setNewCoupon("");
  };

  const handleChangeStatus = (status: string) => {
    setOpenNewStatus(false);
    handleCloseAdvanced();
    if (status !== orderStatuses[orderStatus].slug) {
      orderStatuses.forEach((orderStatus, index) => {
        if (orderStatus.slug === status) {
          setOrderStatus(index);
        }
      });

      setLoadingStatus(true);
      editOrderStatus(orderId, status)
        .then((res) => {
          setOrder({ ...(order as any), status: status });

          setLoadingStatus(false);
          setSuccessMessage(translation.savedMessage);
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setLoadingStatus(false);
          if (order) {
            orderStatuses.forEach((status, index) => {
              if (status.slug === order.status) {
                setOrderStatus(index);
              }
            });
          }
        });
    }
  };

  const saveStatus = () => {
    if (orderStatus) {
      setLoadingStatus(true);
      editOrderStatus(orderId, orderStatus).then((res) => {
        if (res.status < 300) {
          setLoadingStatus(false);
          setSuccessStatus(true);
          setTimeout(() => {
            setSuccessStatus(false);
          }, 6000);
        }
      });
    }
  };

  const generateAwb = () => {
    if (order) {
      if (order["@id"]) {
        if (order.shippingMethod["@id"]) {
          setLoadingAwb(true);
          createAwb(order["@id"], order.shippingMethod["@id"])
            .then((res) => {
              setLoadingAwb(false);
              if (res.status < 300) {
                setAwb(res.data);
              }
            })
            .catch((e) => {
              setLoadingAwb(false);
              setErrorMessage(errorMessage(e));
            });
        }
      }
    }
  };

  const generateInvoice = () => {
    if (order) {
      if (order["@id"]) {
        setLoadingAwb(true);
        createInvoice({
          tenant: tenants[selectedTenant]["@id"],
          shopOrder: order["@id"],
        })
          .then((res) => {
            setLoadingAwb(false);
            setSuccessMessage(translation.invoice.invoiceGenerated);
            if (res.status < 300) {
              setInvoice(res.data);
            }
          })
          .catch((e) => {
            setLoadingAwb(false);
            setErrorMessage(errorMessage(e));
          });
      }
    }
  };

  const handleCloseEditProducts = () => {
    setOpenEditProducts(false);
  };

  const handleSaveNewOrderProducts = () => {
    if (order) {
      const newOrder: IOrder = { ...order };
      newOrder.products = orderProducts;
      setOpenEditProductsConfirmation(false);
      setLoading(true);
      updateOrder(newOrder)
        .then((res) => {
          setLoading(false);
          setUpdateTrigger(!updateTrigger);
          handleCloseEditProducts();
          setSuccessMessage(translation.order.saved);
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setLoading(false);
        });
    }
  };

  return (
    <>
      {orderisLoading || tenants == null ? (
        <LoadingIcon />
      ) : (
        <>
          {order && (
            <Box>
              <PageHeader>
                {translation.orderTitle + " #" + order.id}
              </PageHeader>
              <Stack spacing={2}>
                <Paper sx={{ padding: 2 }}>
                  <Stack spacing={4}>
                    <Stack
                      direction="row"
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Stack direction="row" spacing={2} alignItems={"center"}>
                        {order.status == "pending" && (
                          <>
                            <LoadingButton
                              loading={loadingStatus}
                              variant={
                                order.status == "pending"
                                  ? "contained"
                                  : "outlined"
                              }
                              disabled
                              size="small"
                              onClick={() => handleOpenChangeStatus("pending")}
                            >
                              {translation.order.pending}
                            </LoadingButton>
                            <SvgIcon>
                              <FastForward />
                            </SvgIcon>
                          </>
                        )}
                        {order.status == "abandoned" && (
                          <LoadingButton
                            loading={loadingStatus}
                            variant={
                              "abandoned" == order.status
                                ? "contained"
                                : "outlined"
                            }
                            disabled
                            size="small"
                            onClick={() => handleOpenChangeStatus("abandoned")}
                          >
                            {translation.order.abandoned}
                          </LoadingButton>
                        )}
                        <LoadingButton
                          loading={loadingStatus}
                          variant={
                            orderStatuses[orderStatus].active >= 1
                              ? "contained"
                              : "outlined"
                          }
                          size="small"
                          onClick={() => handleOpenChangeStatus("processing")}
                        >
                          {translation.order.processing}
                        </LoadingButton>

                        {order.status == "partially_shipped" && (
                          <>
                            <SvgIcon
                              color={
                                orderStatuses[orderStatus].active >= 2
                                  ? "primary"
                                  : undefined
                              }
                            >
                              <FastForward />
                            </SvgIcon>
                            <LoadingButton
                              loading={loadingStatus}
                              variant={
                                orderStatuses[orderStatus].active >= 2
                                  ? "contained"
                                  : "outlined"
                              }
                              size="small"
                              onClick={() =>
                                handleOpenChangeStatus("partially_shipped")
                              }
                            >
                              {translation.order.partiallyShipped}
                            </LoadingButton>
                          </>
                        )}
                        <SvgIcon
                          color={
                            orderStatuses[orderStatus].active >= 3
                              ? "primary"
                              : undefined
                          }
                        >
                          <FastForward />
                        </SvgIcon>
                        <LoadingButton
                          loading={loadingStatus}
                          variant={
                            orderStatuses[orderStatus].active >= 3
                              ? "contained"
                              : "outlined"
                          }
                          size="small"
                          onClick={() => handleOpenChangeStatus("shipped")}
                        >
                          {translation.order.shipped}
                        </LoadingButton>
                        <SvgIcon
                          color={
                            orderStatuses[orderStatus].active >= 4
                              ? "primary"
                              : undefined
                          }
                        >
                          <FastForward />
                        </SvgIcon>
                        <LoadingButton
                          loading={loadingStatus}
                          variant={
                            orderStatuses[orderStatus].active >= 4
                              ? "contained"
                              : "outlined"
                          }
                          size="small"
                          onClick={() => handleOpenChangeStatus("completed")}
                        >
                          {translation.order.completed}
                        </LoadingButton>
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          flexItem
                          sx={{ marginX: 3 }}
                        />

                        {order.status == "returned" && (
                          <LoadingButton
                            loading={loadingStatus}
                            variant={
                              order.status == "returned"
                                ? "contained"
                                : "outlined"
                            }
                            disabled
                            size="small"
                            onClick={() => handleOpenChangeStatus("returned")}
                          >
                            {translation.order.returned}
                          </LoadingButton>
                        )}
                        {order.status == "refunded" && (
                          <LoadingButton
                            loading={loadingStatus}
                            variant={
                              order.status == "refunded"
                                ? "contained"
                                : "outlined"
                            }
                            disabled
                            size="small"
                            onClick={() => handleOpenChangeStatus("refunded")}
                          >
                            {translation.order.refunded}
                          </LoadingButton>
                        )}
                        {order.status == "disputed" && (
                          <LoadingButton
                            loading={loadingStatus}
                            variant={
                              order.status == "disputed"
                                ? "contained"
                                : "outlined"
                            }
                            disabled
                            size="small"
                            onClick={() => handleOpenChangeStatus("disputed")}
                          >
                            {translation.order.disputed}
                          </LoadingButton>
                        )}
                        {order.status == "canceled" && (
                          <LoadingButton
                            loading={loadingStatus}
                            variant={
                              order.status == "canceled"
                                ? "contained"
                                : "outlined"
                            }
                            disabled
                            size="small"
                            onClick={() => handleOpenChangeStatus("canceled")}
                          >
                            {translation.order.canceled}
                          </LoadingButton>
                        )}
                        <Button
                          id="demo-customized-button"
                          aria-controls={
                            openAdvanced ? "demo-customized-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={openAdvanced ? "true" : undefined}
                          variant="outlined"
                          size="small"
                          onClick={handleClickAdvanced}
                          endIcon={<KeyboardArrowDownIcon />}
                        >
                          {translation.order.advanced}
                        </Button>
                        <StyledMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorElAdvanced}
                          open={openAdvanced}
                          onClose={handleCloseAdvanced}
                        >
                          <MenuItem
                            onClick={() =>
                              handleOpenChangeStatus("partially_shipped")
                            }
                            disableRipple
                          >
                            {translation.order.partiallyShipped}
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleOpenChangeStatus("canceled")}
                            disableRipple
                          >
                            {translation.order.canceled}
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleOpenChangeStatus("returned")}
                            disableRipple
                          >
                            {translation.order.returned}
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleOpenChangeStatus("refunded")}
                            disableRipple
                          >
                            {translation.order.refunded}
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleOpenChangeStatus("disputed")}
                            disableRipple
                          >
                            {translation.order.disputed}
                          </MenuItem>
                        </StyledMenu>
                      </Stack>
                      <Box>
                        <Stack direction={"row"} spacing={1}>
                          <Typography>
                            {translation.order.paymentMethod}:
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 500,
                            }}
                            color={"primary.dark"}
                          >
                            {order.payment.label}
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                    <OrderProductsList order={order} />
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      spacing={2}
                      sx={{ paddingY: 4 }}
                    >
                      <Stack spacing={1}>
                        {order.coupons?.length > 0 && (
                          <Stack spacing={1}>
                            <Typography>
                              {translation.order.usedCoupon}:
                            </Typography>
                            {order.coupons?.map((coupon) => (
                              <Typography key={coupon.name}>
                                {coupon.name}
                              </Typography>
                            ))}
                          </Stack>
                        )}
                        <LoadingButton
                          variant="outlined"
                          disabled={invoice !== null}
                          loading={loadingAwb}
                          onClick={() => setOpenApplyCoupon(true)}
                        >
                          {order.coupons?.length > 0
                            ? translation.order.changeCoupon
                            : translation.order.applyCoupon}
                        </LoadingButton>
                      </Stack>

                      <Box></Box>
                      <Stack spacing={1} alignItems={"flex-end"}>
                        <Stack direction={"row"} spacing={1}>
                          <Typography>{translation.order.subtotal}</Typography>
                          <Box sx={{ width: "100px" }}>
                            <Typography align="right">
                              {tenants[selectedTenant].settings.taxIncluded
                                ? order.totalPrice?.netPrice
                                : order.totalPrice?.grossPrice}
                            </Typography>
                          </Box>
                          <Typography>
                            {order.totalPrice?.priceCurrency}
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                          <Typography>{translation.order.shipping}</Typography>
                          <Box sx={{ width: "100px" }}>
                            <Typography align="right">
                              {tenants[selectedTenant].settings.taxIncluded
                                ? order.shippingMethodSnapshot?.netPrice
                                : order.shippingMethodSnapshot?.grossPrice}
                            </Typography>
                          </Box>
                          <Typography>
                            {tenants[selectedTenant].settings.defaultCurrency}
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                          <Typography>
                            {translation.order.totalDiscount}
                          </Typography>
                          <Box sx={{ width: "100px" }}>
                            <Typography align="right">
                              {order.totalDiscount?.netPrice > 0 ? "-" : ""}
                              {tenants[selectedTenant].settings.taxIncluded
                                ? order.totalDiscount?.netPrice
                                : order.totalDiscount?.grossPrice}
                            </Typography>
                          </Box>
                          <Typography>
                            {order.totalDiscount.priceCurrency}
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                          <Typography>{translation.order.total}</Typography>
                          <Box sx={{ width: "100px" }}>
                            <Typography align="right">
                              {tenants[selectedTenant].settings.taxIncluded
                                ? order.finalPrice.netPrice
                                : order.finalPrice.grossPrice}
                            </Typography>
                          </Box>
                          <Typography>
                            {order.finalPrice.priceCurrency}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Box>
                      <LoadingButton
                        variant="contained"
                        disabled={invoice !== null}
                        loading={loadingAwb}
                        onClick={() => {
                          setOpenEditProducts(true);
                        }}
                      >
                        {translation.order.editProducts}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Paper>

                <Paper sx={{ padding: 2 }}>
                  <Stack spacing={2}>
                    <Typography>{translation.order.customer}:</Typography>
                    <Stack>
                      <Typography color={"primary"}>
                        {order.customer.firstName !== null
                          ? order.customer.firstName +
                            " " +
                            order.customer.lastName
                          : order.shippingAddress.name}
                      </Typography>
                      <Typography color={"text.disabled"}>
                        {order.customer.email}
                      </Typography>
                      <Typography color={"text.disabled"}>
                        {order.customer.phoneNumber}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
                <Paper sx={{ padding: 2 }}>
                  <Stack spacing={2}>
                    <Stack direction={"row"} spacing={1}>
                      <Typography>{translation.order.delivery}:</Typography>
                      {order.shippingMethod ? (
                        <Typography
                          sx={{
                            fontWeight: 500,
                          }}
                          color={"primary.dark"}
                        >
                          {order.shippingMethod.name}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Stack>{" "}
                    {order.shippingMethodSnapshot.courier !== null ? (
                      awb === null ? (
                        <Box>
                          <LoadingButton
                            onClick={() => generateAwb()}
                            loading={loadingAwb}
                          >
                            {translation.order.generateAwb}
                          </LoadingButton>
                          <Typography color="error">{awbError}</Typography>
                        </Box>
                      ) : (
                        <Typography>Awb: {awb.awb}</Typography>
                      )
                    ) : (
                      ""
                    )}
                  </Stack>
                </Paper>
                <Paper sx={{ padding: 2 }}>
                  <Stack spacing={2}>
                    <Typography>{translation.invoice.invoice}</Typography>
                    {invoice === null ? (
                      <Box>
                        <LoadingButton
                          variant="contained"
                          onClick={() => generateInvoice()}
                          loading={loadingAwb}
                        >
                          {translation.order.generateInvoice}
                        </LoadingButton>
                      </Box>
                    ) : (
                      <Stack direction="row" justifyContent={"space-between"}>
                        <Link
                          component="button"
                          variant="body1"
                          onClick={() => {
                            getInvoiceDownload(
                              invoice["@id"] ? invoice["@id"] : ""
                            ).then((res) => {
                              if (res.data.url) {
                                window.open(res.data.url, "_blank");
                              }
                            });
                          }}
                          color="primary"
                        >
                          #{invoice.fullNumber}
                        </Link>
                        <Typography>
                          {formattedDate(invoice.createdAt)}
                        </Typography>
                        <Typography>{invoice.status}</Typography>
                      </Stack>
                    )}
                  </Stack>
                </Paper>
                <Paper sx={{ padding: 2 }}>
                  <Stack spacing={2}>
                    <Typography>
                      {translation.order.shippingAddress}:
                    </Typography>
                    <Box>
                      <AddressCard
                        address={order.shippingAddress}
                      ></AddressCard>
                    </Box>
                    <Box>
                      <Button
                        variant={"contained"}
                        onClick={() => {
                          setAddressToEdit(order.shippingAddress);
                          setShowNewAddress(true);
                        }}
                      >
                        {translation.customers.editAddress}
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
                <Paper sx={{ padding: 2 }}>
                  <Stack spacing={2}>
                    <Typography>{translation.order.billingAddress}:</Typography>
                    <Box>
                      <AddressCard address={order.billingAddress}></AddressCard>
                    </Box>
                    <Box>
                      <Button
                        variant={"contained"}
                        onClick={() => {
                          setAddressToEdit(order.billingAddress);
                          setShowNewAddress(true);
                        }}
                      >
                        {translation.customers.editAddress}
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
              </Stack>
              <Drawer
                anchor="right"
                open={openEditProducts}
                sx={{
                  zIndex: 1000,
                  "& .MuiDrawer-paper": {
                    width: `calc(100% - ${drawerWidth}px)`,
                  },
                }}
                onClose={() => handleCloseEditProducts()}
              >
                <Toolbar />
                <Stack sx={{ p: 4, height: "100%" }}>
                  <Box flexGrow={1}>
                    <EditOrderProducts
                      orderProducts={orderProducts}
                      setOrderProducts={setOrderProducts}
                    ></EditOrderProducts>
                  </Box>
                  <Box>
                    <Stack direction={"row"} spacing={2}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          handleCloseEditProducts();
                        }}
                      >
                        {translation.closeButton}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setOpenEditProductsConfirmation(true);
                        }}
                      >
                        {translation.saveButton}
                      </Button>
                    </Stack>
                  </Box>
                </Stack>
              </Drawer>
              <Dialog
                open={openEditProductsConfirmation}
                onClose={() => setOpenEditProductsConfirmation(false)}
                aria-labelledby="alert-dialog-title-confirm-delete"
                aria-describedby="alert-dialog-confirm-delete"
              >
                <DialogTitle id="alert-dialog-title-confirm-delete">
                  {translation.order.editProducts}
                </DialogTitle>
                <DialogContent>
                  <Typography>
                    {translation.order.editProductsConfirmation}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant={"outlined"}
                    onClick={() => setOpenEditProductsConfirmation(false)}
                  >
                    {translation.closeButton}
                  </Button>
                  <Button
                    variant={"contained"}
                    onClick={() => {
                      handleSaveNewOrderProducts();
                    }}
                  >
                    {translation.saveButton}
                  </Button>
                </DialogActions>
              </Dialog>
              <AddressForm
                updateTrigger={updateTrigger}
                setUpdateTrigger={setUpdateTrigger}
                setShowNewAddress={setShowNewAddress}
                showNewAddress={showNewAddress}
                addressToEdit={addressToEdit}
                setAddressToEdit={setAddressToEdit}
              ></AddressForm>
            </Box>
          )}
        </>
      )}
      <Dialog open={openNewStatus} onClose={() => setOpenNewStatus(false)}>
        <DialogTitle>{translation.order.changeStatus}</DialogTitle>
        <DialogContent>
          <Typography>{translation.order.changeStatusMessage}</Typography>
          <Typography fontWeight={700}>{newStatus}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={() => setOpenNewStatus(false)}>
            {translation.closeButton}
          </Button>
          <Button
            variant={"contained"}
            onClick={() => {
              if (newStatus) {
                handleChangeStatus(newStatus);
              }
            }}
          >
            {translation.updateButton}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openApplyCoupon} onClose={() => handleCloseApplyCoupon()}>
        <DialogContent>
          <TextField
            label={translation.order.applyCoupon}
            value={newCoupon}
            onChange={(e) => setNewCoupon(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={() => handleCloseApplyCoupon()}>
            {translation.closeButton}
          </Button>
          <LoadingButton
            variant={"contained"}
            onClick={() => {
              if (newCoupon) {
                handleApplyCoupon();
              }
            }}
            loading={loading}
          >
            {translation.updateButton}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderView;
