import React, { useEffect, useState } from "react";
import { Box, Typography, Stack, Button, ToggleButton } from "@mui/material";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { getOrdersByTenant } from "services/orders";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Collapse from "@mui/material/Collapse";
import EnhancedTableHead, {
  HeadCell,
  Order,
} from "components/Table/EnhancedTableHead";
import { Add, HiveOutlined } from "@mui/icons-material";
import { orderStatuses } from "helpers/orderStatuses";
import PageHeader from "components/PageHeader";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { routeNames } from "routes";
import { errorMessage } from "helpers";
import { useSnackbarContext } from "components/contexts/SnackbarContext";

interface Data {
  id: number;
  customer: string;
  date: string;
  status: string;
  ammount: string;
}

const headCells: HeadCell[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Id",
    sorting: false,
  },
  {
    id: "customer",
    numeric: true,
    disablePadding: false,
    label: "Customer",
    sorting: false,
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Date",
    sorting: false,
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
    sorting: false,
  },
  {
    id: "ammount",
    numeric: true,
    disablePadding: false,
    label: "Ammount",
    sorting: false,
  },
];

const Orders = () => {
  const { tenantsIsLoading, selectedTenant, tenants } = useUserContext();
  const [orders, setOrders] = useState<any>(null);
  const [ordersCount, setOrdersCount] = useState(0);
  const [pagesNumber, setPagesNumber] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [rows, setRows] = useState<Data[]>([]);
  const translation = useTranslation();
  const { loading, setLoading } = useLoadingContext();

  const navigate = useNavigate();

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState<string>("id");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, orderId: number) => {
    navigate("/orders/view/" + orderId);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const ordersToData = (orders: any) => {
    const data = orders.map((order: any) => {
      return {
        id: order.id,
        customer:
          order.customer.firstName !== null
            ? order.customer.firstName + " " + order.customer.lastName
            : order.shippingAddress.name,
        date: new Date(order.createdAt).toLocaleDateString("ro-RO"),
        status: order.status,
        ammount:
          order.finalPrice.netPrice.toString() +
          " " +
          order.finalPrice.priceCurrency,
      };
    });

    return data;
  };

  useEffect(() => {
    setLoading(true);

    if (tenants !== null) {
      getOrdersByTenant(
        tenants[selectedTenant].id,
        page + 1,
        rowsPerPage,
        orderBy,
        order,
        statusFilter
      )
        .then((res) => {
          setOrders(res.data["hydra:member"]);
          setRows(ordersToData(res.data["hydra:member"]));
          setOrdersCount(res.data["hydra:totalItems"]);
          setPagesNumber(Math.ceil(res.data["hydra:totalItems"] / rowsPerPage));
          setLoading(false);
        })
        .catch((e) => setErrorMessage(errorMessage(e)));
    }
  }, [
    selectedTenant,
    tenants,
    page,
    rowsPerPage,
    orderBy,
    order,
    statusFilter,
  ]);

  const handleChangeStatusFilter = (event: SelectChangeEvent) => {
    setStatusFilter(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [selectedTenant]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Stack direction="row" justifyContent={"space-between"}>
          <PageHeader>{translation.ordersTitle}</PageHeader>
          <Stack direction="row" spacing={2}>
            <Box>
              <Button
                variant="outlined"
                startIcon={<HiveOutlined />}
                onClick={() => setShowFilters(!showFilters)}
              >
                {translation.filters}
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  navigate(routeNames.newOrder);
                }}
              >
                {translation.newOrderTitle}
              </Button>
            </Box>
          </Stack>
        </Stack>
        <Stack>
          <Collapse in={showFilters}>
            <Stack direction="row" sx={{ marginBottom: 4 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="status-select-standard-label">
                  {translation.status}
                </InputLabel>
                <Select
                  labelId="status-select-standard-label"
                  id="status-select-standard"
                  value={statusFilter}
                  onChange={handleChangeStatusFilter}
                  label={translation.status}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {orderStatuses.map((orderStatus) => (
                    <MenuItem key={orderStatus.slug} value={orderStatus.slug}>
                      {orderStatus.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Collapse>
        </Stack>
        {rows.length > 0 ? (
          <>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                >
                  <EnhancedTableHead
                    headCells={headCells}
                    numSelected={selected.length}
                    order={order as any}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {!loading ? (
                      rows.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.id)}
                            tabIndex={-1}
                            key={row.id}
                            style={{
                              height: 100,
                              cursor: "pointer",
                            }}
                          >
                            <TableCell component="th">
                              <Box>{row.id}</Box>
                            </TableCell>
                            <TableCell>{row.customer}</TableCell>
                            <TableCell>{row.date}</TableCell>
                            <TableCell>{row.status}</TableCell>
                            <TableCell>{row.ammount}</TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow
                        style={{
                          height: 100 * rowsPerPage,
                        }}
                      >
                        <TableCell colSpan={6}>
                          <Stack alignItems="center">
                            <CircularProgress />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={ordersCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                backIconButtonProps={
                  loading
                    ? {
                        disabled: loading,
                      }
                    : undefined
                }
                nextIconButtonProps={
                  loading
                    ? {
                        disabled: loading,
                      }
                    : undefined
                }
              />
            </Paper>
          </>
        ) : (
          !loading && <Typography>{translation.order.noOrders}</Typography>
        )}
      </Box>
    </>
  );
};

export default Orders;
