import { useState } from "react";

export default function useTenant() {
  const [selectedTenant, setSelectedTenant] = useState(0);
  const [tenants, setTenants] = useState(null);
  const [tenantsIsLoading, setTennantsIsLoading] = useState(true);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const selectTenant = (tenant) => {
    setSelectedTenant(parseInt(tenant));
    localStorage.setItem("selectedTenant", tenant);
  };

  return {
    selectedTenant,
    setSelectedTenant,
    selectTenant,
    tenants,
    setTenants,
    tenantsIsLoading,
    setTennantsIsLoading,
    updateTrigger,
    setUpdateTrigger,
  };
}
