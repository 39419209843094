import React, { useEffect, useState } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import EnhancedTableHead, {
  HeadCell,
  Order,
} from "components/Table/EnhancedTableHead";
import { Add } from "@mui/icons-material";
import { routeNames } from "routes";
import { useLoadingContext } from "components/contexts/LoadingContext";
import MarketingMenu from "components/Menu/MarketingMenu";
import { getCouponsByTenant } from "services/tenants";
import { ICoupon } from "interfaces";
import { errorMessage, excerpt, formattedDate } from "helpers";
import { useSnackbarContext } from "components/contexts/SnackbarContext";

interface Data {
  id: number;
  name: string;
  amount: string;
  endDate: string;
  description?: string;
}

const headCells: HeadCell[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Id",
    sorting: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Coupon Name",
    sorting: false,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    sorting: false,
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
    sorting: false,
  },
  // {
  //   id: "isActive",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Is Active",
  //   sorting: false,
  // },
  {
    id: "endDate",
    numeric: false,
    disablePadding: false,
    label: "Expiry Date",
    sorting: false,
  },
];

const Coupons = () => {
  const { selectedTenant, tenants } = useUserContext();
  const [rowsCount, setRowsCount] = useState(0);
  const [_pagesNumber, setPagesNumber] = useState(0);
  const [rows, setRows] = useState<Data[]>([]);
  const translation = useTranslation();

  const { loading, setLoading } = useLoadingContext();
  const navigate = useNavigate();

  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<string>("id");
  const [selected, _setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const { setErrorMessage } = useSnackbarContext();
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, orderId: number) => {
    navigate("/coupons/edit/" + orderId);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const dataToRows = (data: ICoupon[]) => {
      const rows = data.map((coupon: ICoupon) => {
        return {
          id: coupon.id ? coupon.id : 0,
          name: coupon.name,
          amount:
            coupon.amount +
            (coupon.isPercentage
              ? "%"
              : " " + tenants[selectedTenant].settings.defaultCurrency),
          endDate: formattedDate(coupon.endDate),
          description: excerpt(coupon.description, 20),
        };
      });

      return rows;
    };

    setLoading(true);

    if (tenants !== null) {
      getCouponsByTenant(
        tenants[selectedTenant].id,
        page + 1,
        rowsPerPage,
        orderBy,
        order
      )
        .then((res) => {
          setRows(dataToRows(res.data["hydra:member"]));
          setRowsCount(res.data["hydra:totalItems"]);
          setPagesNumber(Math.ceil(res.data["hydra:totalItems"] / rowsPerPage));
          setLoading(false);
        })
        .catch((e) => setErrorMessage(errorMessage(e)));
    }
  }, [selectedTenant, tenants, page, rowsPerPage, orderBy, order, setLoading]);

  useEffect(() => {
    setPage(0);
  }, [selectedTenant]);

  return (
    <>
      <MarketingMenu />
      <Box sx={{ width: "100%" }}>
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <Box sx={{ mb: 2 }}>
            <Button
              variant={"contained"}
              startIcon={<Add />}
              onClick={() => navigate(routeNames.couponsCreate)}
            >
              {translation.coupons.newCoupon}
            </Button>
          </Box>
        </Stack>
        {rows.length > 0 ? (
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  headCells={headCells}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {!loading ? (
                    rows.map((row) => {
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          tabIndex={-1}
                          key={row.id}
                          style={{
                            height: 100,
                            cursor: "pointer",
                          }}
                        >
                          <TableCell component="th">
                            <Box>{row.id}</Box>
                          </TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.description}</TableCell>

                          <TableCell>{row.amount}</TableCell>
                          <TableCell>{row.endDate}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow
                      style={{
                        height: 100 * rowsPerPage,
                      }}
                    >
                      <TableCell colSpan={6}>
                        <Stack alignItems="center">
                          <CircularProgress />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={rowsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={
                loading
                  ? {
                      disabled: loading,
                    }
                  : undefined
              }
              nextIconButtonProps={
                loading
                  ? {
                      disabled: loading,
                    }
                  : undefined
              }
            />
          </Paper>
        ) : (
          !loading && <Typography>{translation.coupons.noCoupons}</Typography>
        )}
      </Box>
    </>
  );
};

export default Coupons;
