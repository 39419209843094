import React, { useContext } from "react";
import useFindUser from "components/customHooks/useFindUser";
import useTenant from "components/customHooks/useTenant";

const UserContext = React.createContext();
export const useUserContext = () => useContext(UserContext);

export default function UserContextProvider({ children }) {
  const { user, setUser, loginUser, refreshUser, isLoading, userId } =
    useFindUser();
  const {
    selectedTenant,
    setSelectedTenant,
    selectTenant,
    tenants,
    setTenants,
    tenantsIsLoading,
    setTennantsIsLoading,
    updateTrigger,
    setUpdateTrigger,
  } = useTenant();

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        loginUser,
        refreshUser,
        isLoading,
        userId,
        selectedTenant,
        setSelectedTenant,
        selectTenant,
        tenants,
        setTenants,
        tenantsIsLoading,
        setTennantsIsLoading,
        updateTrigger,
        setUpdateTrigger,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
