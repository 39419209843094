import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  InputBase,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import { getDoTasksByTenant, newDoTask, updateDoTask } from "services/do";
import dayjs from "dayjs";
import { useUserContext } from "components/contexts/UserContext";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { IDoTask } from "interfaces";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { delay, errorMessage, formattedDate } from "helpers";
import DoTaskForm from "./DoForm";
import { EditOutlined, HiveOutlined, SendOutlined } from "@mui/icons-material";
import { TransitionGroup } from "react-transition-group";
import ding from "assets/sounds/ding.mp3";
import TaskList from "./TaskList";

const Do = () => {
  const [audio] = useState(new Audio(ding));

  const { userId, selectedTenant, tenants } = useUserContext();
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const { loading, setLoading } = useLoadingContext();
  const { pathname } = useLocation();
  const translation = useTranslation();
  const [newDoName, setNewDoName] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [showNewDoTask, setShowNewDoTask] = useState(false);
  const [doTaskToEdit, setDoTaskToEdit] = useState<IDoTask | null>(null);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [doneFilter, setDoneFilter] = useState(false);
  const [disableDone, setDisableDone] = useState(false);

  const [doTasks, setDoTasks] = useState<IDoTask[]>([]);

  const createDoTask = (e: any) => {
    if (e.keyCode === 13) {
      if (newDoName !== "") {
        newDoTask({
          name: newDoName,
          owner: "/users/" + userId,
          tenant: "/tenants/" + tenants[selectedTenant].id,
          list: e.shiftKey ? "today" : "do",
        })
          .then((response) => {
            setDoTasks((prev) => [
              response.data as unknown as IDoTask,
              ...prev,
            ]);
            setSuccessMessage(translation.savedMessage);
            setNewDoName("");
          })
          .catch((e) => setErrorMessage(e.response.data.message));
      }
    }
  };

  useEffect(() => {
    setLoading(true);

    if (tenants !== null) {
      getDoTasksByTenant(tenants[selectedTenant].id, doneFilter)
        .then((res) => {
          setDoTasks(res.data["hydra:member"]);

          setLoading(false);
        })
        .catch((e) => setErrorMessage(errorMessage(e)));
    }
  }, [selectedTenant, tenants, updateTrigger, doneFilter]);

  const toggleDoTask = (doTask: IDoTask) => {
    if (!disableDone) {
      setDisableDone(true);
      if (doTask.done == false) {
        audio.currentTime = 0;
        audio.play();
      }
      setDoTasks((prev) => [
        ...prev.map((doTaskMap) => {
          if (doTaskMap == doTask) {
            return { ...doTask, done: !doTask.done };
          } else {
            return doTaskMap;
          }
        }),
      ]);
      updateDoTask({ ...doTask, done: !doTask.done })
        .then((res) => {
          delay(1000).then(() => {
            setDoTasks((prev) => [...prev.filter((i) => i.id !== doTask.id)]);
          });
          setSuccessMessage(translation.savedMessage);
          setDisableDone(false);
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setDisableDone(false);
        });
    }
  };
  return (
    <Box>
      <Stack spacing={4}>
        <Stack>
          {/* <Stack alignItems={"end"}>
                        <Button
                            variant="outlined"
                            startIcon={<HiveOutlined />}
                            onClick={() => setShowFilters(!showFilters)}
                        >
                            {translation.filters}
                        </Button>
                    </Stack>
                    <Collapse in={showFilters}>
                        <Stack
                            direction="row"
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                        >
                            <Switch
                                checked={doneFilter}
                                onChange={(e) => setDoneFilter(!doneFilter)}
                            ></Switch>
                            <Typography> done tasks</Typography>
                        </Stack>
                    </Collapse> */}
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Switch
              checked={doneFilter}
              onChange={(e) => setDoneFilter(!doneFilter)}
            ></Switch>
            <Typography> done tasks</Typography>
          </Stack>
        </Stack>
        <Stack>
          <Paper sx={{ p: 2, mb: 4 }}>
            <Stack>
              <Stack
                sx={{ width: "100%" }}
                direction={"row"}
                spacing={2}
                alignItems={"center"}
              >
                <SendOutlined color={"disabled"} />
                <InputBase
                  sx={{ width: "100%" }}
                  required
                  id="outlined-required"
                  value={newDoName}
                  onChange={(e) => {
                    setNewDoName(e.target.value);
                  }}
                  onKeyDown={(e) => createDoTask(e)}
                  placeholder={translation.do.newDoLabel}
                />
              </Stack>
            </Stack>
          </Paper>
          <Stack spacing={4}>
            <Box>
              <TaskList
                title={translation.do.dos}
                doTasks={doTasks.filter((doTask) => doTask.list === "do")}
                toggleDoTask={toggleDoTask}
                setDoTaskToEdit={setDoTaskToEdit}
                setShowNewDoTask={setShowNewDoTask}
              ></TaskList>
            </Box>
            <Box>
              <TaskList
                title={translation.do.today}
                doTasks={doTasks.filter((doTask) => doTask.list === "today")}
                toggleDoTask={toggleDoTask}
                setDoTaskToEdit={setDoTaskToEdit}
                setShowNewDoTask={setShowNewDoTask}
              ></TaskList>
            </Box>
            <Box>
              <TaskList
                title={translation.do.watch}
                doTasks={doTasks.filter((doTask) => doTask.list === "watch")}
                toggleDoTask={toggleDoTask}
                setDoTaskToEdit={setDoTaskToEdit}
                setShowNewDoTask={setShowNewDoTask}
              ></TaskList>
            </Box>
            <Box>
              <TaskList
                title={translation.do.later}
                doTasks={doTasks.filter((doTask) => doTask.list === "later")}
                toggleDoTask={toggleDoTask}
                setDoTaskToEdit={setDoTaskToEdit}
                setShowNewDoTask={setShowNewDoTask}
              ></TaskList>
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <DoTaskForm
        showNewDoTask={showNewDoTask}
        setShowNewDoTask={setShowNewDoTask}
        doTaskToEdit={doTaskToEdit}
        setDoTaskToEdit={setDoTaskToEdit}
        updateTrigger={updateTrigger}
        setUpdateTrigger={setUpdateTrigger}
      ></DoTaskForm>
    </Box>
  );
};

export default Do;
