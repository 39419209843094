import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Button,
  IconButton,
  Collapse,
  TableHead,
} from "@mui/material";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import EnhancedTableHead, {
  HeadCell,
  Order,
} from "components/Table/EnhancedTableHead";
import { Add } from "@mui/icons-material";
import { routeNames } from "routes";
import { useLoadingContext } from "components/contexts/LoadingContext";
import MarketingMenu from "components/Menu/MarketingMenu";
import {
  exportNewsletterSubscribersByTenant,
  getContactEntriesByTenant,
  getNewsletterSubscribersByTenant,
} from "services/tenants";
import { IContactEntry, INewsletterSubscriber } from "interfaces";
import { errorMessage, excerpt, formattedDate } from "helpers";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { subscribersCSV } from "helpers/subscribersCSV";
import { LoadingButton } from "@mui/lab";
import { CSVLink } from "react-csv";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import NewsletterSettings from "./NewletterSettings";

interface Data {
  id: number;
  email: string;
  name: string;
  message: string;
  consent: boolean;
  createdAt: string;
}

function Row(props: { row: Data }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const t = useTranslation();

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.email}
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.createdAt}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{t.contactEntries.message}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>{t.contactEntries.consent}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {row.message}
                    </TableCell>

                    <TableCell>{row.consent ? "yes" : "no"}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const headCells: HeadCell[] = [
  {
    id: "collapse",
    numeric: false,
    disablePadding: false,
    label: "",
    sorting: false,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    sorting: false,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sorting: false,
  },

  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Date",
    sorting: false,
  },
];

const ContactEntries = () => {
  const { selectedTenant, tenants } = useUserContext();
  const [rowsCount, setRowsCount] = useState(0);
  const [_pagesNumber, setPagesNumber] = useState(0);
  const [rows, setRows] = useState<Data[]>([]);
  const translation = useTranslation();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();

  const { loading, setLoading } = useLoadingContext();
  const [exportLoading, setExportLoading] = useState(false);

  const [exportedSubscribers, setExportedSubscribers] =
    useState<any>(subscribersCSV);
  const navigate = useNavigate();

  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<string>("id");
  const [selected, _setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const csvLink = useRef<any>();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleExport = () => {
  //   setExportLoading(true);
  //   exportContactEntriesByTenant(tenants[selectedTenant].id)
  //     .then((response) => {
  //       setExportLoading(false);
  //       const newExportedSubscribers = [...exportedSubscribers];
  //       const subscribers = response.data["hydra:member"];
  //       subscribers.forEach((subscriber: INewsletterSubscriber) => {
  //         newExportedSubscribers.push([
  //           subscriber.email,
  //           subscriber.firstName,
  //           subscriber.lastName,
  //           subscriber.birthday ? subscriber.birthday.split("T")[0] : "",
  //           subscriber.phoneNumber,
  //           subscriber.consent,
  //           subscriber.createdAt ? subscriber.createdAt.split("T")[0] : "",
  //           subscriber.ip,
  //           subscriber.source,
  //         ]);
  //       });
  //       setExportedSubscribers(newExportedSubscribers);
  //     })
  //     .catch((e) => {
  //       setErrorMessage("Error exporting products!");
  //     });
  // };

  useEffect(() => {
    if (exportedSubscribers.length > 1) {
      csvLink.current.link.click();
      setExportedSubscribers(subscribersCSV);
    }
  }, [exportedSubscribers]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const dataToRows = (data: IContactEntry[]) => {
      const rows = data.map((contactEntry: IContactEntry) => {
        return {
          id: contactEntry.id ? contactEntry.id : 0,
          email: contactEntry.email,
          name: contactEntry.name ? contactEntry.name : "",
          message: contactEntry.message ? contactEntry.message : "",
          consent: contactEntry.consent,
          createdAt: formattedDate(contactEntry.createdAt),
        };
      });

      return rows;
    };

    setLoading(true);

    if (tenants !== null) {
      getContactEntriesByTenant(
        tenants[selectedTenant].id,
        page + 1,
        rowsPerPage,
        orderBy,
        order
      )
        .then((res) => {
          setRows(dataToRows(res.data["hydra:member"]));
          setRowsCount(res.data["hydra:totalItems"]);
          setPagesNumber(Math.ceil(res.data["hydra:totalItems"] / rowsPerPage));
          setLoading(false);
        })
        .catch((e) => setErrorMessage(errorMessage(e)));
    }
  }, [selectedTenant, tenants, page, rowsPerPage, orderBy, order, setLoading]);

  useEffect(() => {
    setPage(0);
  }, [selectedTenant]);

  return (
    <>
      <MarketingMenu />

      {/* <Stack direction={"row"} justifyContent={"flex-end"}>
        <Box sx={{ mb: 2 }}>
          <Box sx={{ my: 2 }}>
            <LoadingButton
              loading={exportLoading}
              variant={"contained"}
              onClick={() => handleExport()}
            >
              {translation.ContactEntries.export}
            </LoadingButton>
            <CSVLink
              data={exportedSubscribers}
              ref={csvLink as any}
              enclosingCharacter='"'
              filename="export-subscribers.csv"
            ></CSVLink>
          </Box>
        </Box>
      </Stack> */}
      <Box sx={{ width: "100%" }}>
        {rows.length > 0 ? (
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  headCells={headCells}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {!loading ? (
                    rows.map((row) => (
                      <Row key={row.email + row.id} row={row} />
                    ))
                  ) : (
                    <TableRow
                      style={{
                        height: 100 * rowsPerPage,
                      }}
                    >
                      <TableCell colSpan={6}>
                        <Stack alignItems="center">
                          <CircularProgress />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={rowsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={
                loading
                  ? {
                      disabled: loading,
                    }
                  : undefined
              }
              nextIconButtonProps={
                loading
                  ? {
                      disabled: loading,
                    }
                  : undefined
              }
            />
          </Paper>
        ) : (
          !loading && (
            <Typography>{translation.contactEntries.noEntries}</Typography>
          )
        )}
      </Box>
    </>
  );
};

export default ContactEntries;
