import { useState, useEffect } from "react";
import { parseJwt } from "helpers";
import { getUser, login, refreshLogin } from "services/users";

export default function useFindUser() {
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function findUser() {
      const userLoggedIn = getUser();

      if (userLoggedIn && userLoggedIn.token) {
        setUserId(userLoggedIn.id);
        setUser(userLoggedIn);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    findUser();
  }, []);

  async function loginUser(email, password) {
    setLoading(true);
    const response = await login(email, password);

    if (response) {
      setUserId(response.id);
      setUser(response);
      setLoading(false);
    } else {
      setLoading(false);
    }
    return new Promise((resolve, reject) => {
      resolve(response);
    });
  }

  async function refreshUser() {
    setLoading(true);
    let response = await refreshLogin();
    if (response) {
      setUserId(response.id);
      setUser(response);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  return {
    user,
    setUser,
    loginUser,
    refreshUser,
    isLoading,
    userId,
  };
}
