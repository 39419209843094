import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useUserContext } from "components/contexts/UserContext";
import { IDropTag } from "interfaces";

const filter = createFilterOptions<IDropTag>();

const AutocompleteTagsField = ({
  controlId,
  label,
  name,
  inputGroupPrepend,
  options,
  placeholder = true,
}: {
  controlId?: string;
  label?: string;
  name: string;
  inputGroupPrepend?: React.ReactNode;
  options: any;
  placeholder?: boolean;
}) => {
  const { selectedTenant, tenants } = useUserContext();
  const [allOptions, setAllOptions] = useState<IDropTag[]>([]);
  const [field, meta, helper] = useField(name);
  const isValid = !meta.error;
  const isInvalid = meta.touched && !isValid;

  useEffect(() => {
    setAllOptions([...options]);
  }, [options]);

  return (
    <>
      <Autocomplete
        id={field.name}
        multiple
        onChange={(event: any, newValue: any) => {
          if (typeof newValue === "string") {
            helper.setValue({
              name: newValue,
              tenant: "/tenants/" + tenants[selectedTenant].id,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            helper.setValue({
              name: newValue.inputValue,
              tenant: "/tenants/" + tenants[selectedTenant].id,
            });
          } else {
            helper.setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.name
          );

          if (inputValue !== "" && !isExisting) {
            filtered.push({
              name: inputValue,
              tenant: "/tenants/" + tenants[selectedTenant].id,
            });
          }
          return filtered;
        }}
        onBlur={() => helper.setTouched(true)}
        defaultValue={field.value}
        value={field.value}
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.id === undefined) {
            return `Add "${option.name}"`;
          }
          // Regular option
          return option.name;
        }}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: any, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              label={option.name}
              {...getTagProps({ index: index })}
            />
          ))
        }
        autoHighlight
        isOptionEqualToValue={(option, value) => {
          if (option.id) {
            return option.id == value.id;
          } else {
            return option.name == value.name;
          }
        }}
        sx={{ width: "100%" }}
        renderOption={(props, option) => {
          const newProps = props as any;
          return (
            <li {...props} key={option.id ? option.id : newProps.id}>
              {newProps.key}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            error={isInvalid}
            helperText={isInvalid ? meta.error : ""}
            variant="standard"
            {...params}
            label={label}
          />
        )}
      />
    </>
  );
};

export default AutocompleteTagsField;
