import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  InputBase,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import { getDoTasksByTenant, newDoTask, updateDoTask } from "services/do";
import dayjs from "dayjs";
import { useUserContext } from "components/contexts/UserContext";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { IDoTask } from "interfaces";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { delay, errorMessage, formattedDate } from "helpers";
import DoTaskForm from "./DoForm";
import { EditOutlined, HiveOutlined, SendOutlined } from "@mui/icons-material";
import { TransitionGroup } from "react-transition-group";
import ding from "assets/sounds/ding.mp3";
import { dosCSV } from "helpers/exportDo";
import { LoadingButton } from "@mui/lab";
import { CSVLink } from "react-csv";

const TaskList = ({
  doTasks,
  title,
  toggleDoTask,
  setDoTaskToEdit,
  setShowNewDoTask,
}: {
  doTasks: IDoTask[];
  title: string;
  toggleDoTask: any;
  setDoTaskToEdit: any;
  setShowNewDoTask: any;
}) => {
  const { loading, setLoading } = useLoadingContext();
  const translation = useTranslation();
  const [newDoName, setNewDoName] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [exportedDos, setExportedDos] = useState<any>(dosCSV);

  const csvLink = useRef<any>();

  const exportTasks = () => {
    const newExportedDos = [...exportedDos];
    doTasks.forEach((doTask: any) => {
      newExportedDos.push([
        doTask.id,
        doTask.name,
        doTask.description ? doTask.description : "",
        doTask.dueDate ? doTask.dueDate : "",
        doTask.done ? doTask.done : "",
        doTask.doneDate ? doTask.doneDate : "",
        doTask.list ? doTask.list : "",
      ]);
    });
    setExportedDos(newExportedDos);
  };

  useEffect(() => {
    if (exportedDos.length > 1) {
      csvLink.current.link.click();
      setExportedDos(dosCSV);
    }
  }, [exportedDos]);

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h5">{title}</Typography>

        <Box sx={{ my: 2 }}>
          <LoadingButton
            size="small"
            loading={exportLoading}
            variant={"outlined"}
            onClick={() => exportTasks()}
          >
            {translation.do.exportTaks}
          </LoadingButton>
          <CSVLink
            data={exportedDos}
            ref={csvLink as any}
            enclosingCharacter='"'
            filename="export-tasks.csv"
          ></CSVLink>
        </Box>
      </Stack>
      <Stack spacing={4}>
        <Paper
          sx={{
            p: "48px 24px 24px 24px",
            borderRadius: "0px 0px 0px 8px",
          }}
        >
          <Stack>
            {doTasks.length > 0 ? (
              <TransitionGroup>
                {doTasks.map((doTask) => (
                  <Collapse key={"list-" + doTask["@id"]}>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      alignItems={"center"}
                      sx={{ minHeight: 100 }}
                    >
                      <Checkbox
                        checked={doTask.done}
                        onClick={(e) => toggleDoTask(doTask)}
                      ></Checkbox>
                      <Box
                        component="div"
                        flex={1}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setDoTaskToEdit(doTask);
                          setShowNewDoTask(true);
                        }}
                      >
                        <Stack
                          direction={"row"}
                          spacing={2}
                          flexGrow={1}
                          alignItems={"center"}
                          sx={{ minHeight: 100 }}
                        >
                          <Stack flexGrow={1}>
                            <Typography component="div">
                              <Box sx={{ fontWeight: "bold", m: 1 }}>
                                {doTask.name}
                              </Box>
                            </Typography>
                            <Box
                              sx={{
                                display: "inline-grid",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  minWidth: 0,
                                }}
                                variant="subtitle1"
                                noWrap
                              >
                                {doTask.description}
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{
                              minWidth: 200,
                              maxWidth: 200,
                            }}
                            flexWrap={"wrap"}
                          >
                            {doTask.tags?.map((tag) => (
                              <Chip
                                key={tag["@id"]}
                                label={tag.name}
                                sx={{ m: 1 }}
                              ></Chip>
                            ))}
                          </Stack>
                          <Stack>
                            <Typography variant="caption">
                              {translation.do.dueDate}
                            </Typography>

                            <Typography variant="body2" noWrap>
                              {formattedDate(doTask.dueDate)}
                            </Typography>
                          </Stack>
                          {doTask.doneDate && (
                            <Stack>
                              <Typography variant="caption">
                                {translation.do.doneDate}
                              </Typography>

                              <Typography variant="body2" noWrap>
                                {formattedDate(doTask.doneDate)}
                              </Typography>
                            </Stack>
                          )}
                        </Stack>
                      </Box>
                    </Stack>
                    <Divider />
                  </Collapse>
                ))}
              </TransitionGroup>
            ) : loading ? (
              <Typography>{translation.loading}</Typography>
            ) : (
              <Typography>{translation.do.noDos}</Typography>
            )}
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
};

export default TaskList;
