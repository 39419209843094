import React, { useContext } from "react";
import useSnackbar from "components/customHooks/useSnackbar";

const SnackbarContext = React.createContext();
export const useSnackbarContext = () => useContext(SnackbarContext);

export default function SnackbarContextProvider({ children }) {
  const { errorMessage, setErrorMessage, successMessage, setSuccessMessage } =
    useSnackbar();

  return (
    <SnackbarContext.Provider
      value={{
        errorMessage,
        setErrorMessage,
        successMessage,
        setSuccessMessage,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
}
