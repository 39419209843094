import React, { useState, useEffect, useMemo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  getLocaleLanguageName,
  getTranslatableItem,
  getTranslatableItemDefaultValues,
  getTranslatableItemFields,
  getTranslatableItems,
  translatables,
  updateItemTranslation,
} from "helpers/translatables";
import useTranslation from "components/customHooks/translations";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import { searchProductsNames } from "services/products";
import { useUserContext } from "components/contexts/UserContext";
import { IProductLocale, ITranslatable } from "interfaces";
import { useLoadingContext } from "components/contexts/LoadingContext";
import TranslatablesList from "./TranslatablesList";
import useTenant from "components/customHooks/useTenant";
import { useTenantContext } from "components/contexts/TenantContext";
import locales from "helpers/locales";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage } from "helpers";
import { updateSettings, updateTenant } from "services/tenants";

export default function TranslationForm({
  translatable,
  selectedItem,
  selectNextItem,
}: {
  translatable: typeof translatables.products;
  selectedItem: ITranslatable | null;
  selectNextItem: any;
}) {
  const t = useTranslation();
  const { loading, setLoading } = useLoadingContext();
  const { tenants, selectedTenant } = useUserContext();
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [locale, setLocale] = React.useState("");
  const [originalItem, setOriginalItem] = React.useState<any>(null);
  const [translations, setTranslations] = React.useState<any>([]);
  const [currentTranslation, setCurrentTranslation] = React.useState<any>(null);

  const { settings } = useTenantContext();

  useEffect(() => {
    if (selectedItem) {
      setLoading(true);
      getTranslatableItem(translatable, selectedItem).then((res) => {
        setOriginalItem(res?.original);
        setTranslations(res?.translations);
        setLoading(false);
      });
    }
  }, [selectedItem, updateTrigger]);

  const storeLanguage = useMemo(() => {
    return getLocaleLanguageName(settings.storeLocale);
  }, [settings]);

  const enabledLanguages = useMemo(() => {
    if (settings.enabledLocales) {
      const enabledLanguagesArray = settings.enabledLocales
        .filter((locale: string) => locale !== settings.storeLocale)
        .map((locale: string) => ({
          locale: locale,
          language: getLocaleLanguageName(locale),
        }));
      if (enabledLanguagesArray.length > 0) {
        setLocale(enabledLanguagesArray[0].locale);
      }
      return enabledLanguagesArray;
    } else {
      return [];
    }
  }, [settings]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setLocale(newValue);
  };

  const translationItemDefaultValues = useMemo(() => {
    let translatableItemValues = getTranslatableItemDefaultValues(translatable);
    setCurrentTranslation(null);
    translations.forEach((translation: any) => {
      if (translation.locale === locale) {
        setCurrentTranslation(translation);
        translatableItemValues = getTranslatableItemDefaultValues(
          translatable,
          translation
        );
      }
    });

    return translatableItemValues;
  }, [locale, translatable, translations, selectedItem]);

  const fields = useMemo(() => {
    return getTranslatableItemFields(translatable);
  }, [translatable]);

  const handleSubmitTranslations = (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
    action: "nextItem" | "nextLanguage"
  ) => {
    setSubmitting(true);
    updateItemTranslation(
      translatable,
      values,
      originalItem,
      currentTranslation,
      locale
    )
      .then((res) => {
        setSubmitting(false);
        setSuccessMessage(t.savedMessage);
        updateTenant(
          {
            ...tenants[selectedTenant].id,
          },
          tenants[selectedTenant].id
        );
        if (action == "nextItem") {
          selectNextItem();
        }
        if (action == "nextLanguage") {
          nextLanguage();
        }
        window.scrollTo(0, 0);
      })
      .catch((e) => {
        setErrorMessage(errorMessage(e));
        setSubmitting(false);
      });
  };

  const nextLanguage = () => {
    let nextIndex = 0;
    for (let i = 0; i < enabledLanguages.length; i++) {
      if (enabledLanguages[i].locale === locale) {
        nextIndex = i + 1;
      }
    }

    if (nextIndex < enabledLanguages.length) {
      setLocale(enabledLanguages[nextIndex].locale);
      window.scrollTo(0, 0);
    } else {
      setLocale(enabledLanguages[0].locale);
      window.scrollTo(0, 0);
    }
  };

  return loading == true || originalItem === null ? (
    <></>
  ) : (
    <Stack direction={"row"} spacing={2} sx={{ width: "100%" }}>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={2}>
          <Typography variant="h5" sx={{ my: 1 }} fontWeight={500}>
            {storeLanguage}
          </Typography>
          <Box sx={{ paddingTop: "5px" }}>
            <Formik
              initialValues={getTranslatableItemDefaultValues(
                translatable,
                originalItem
              )}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
              }}
            >
              {({
                handleSubmit,
                isSubmitting,
                values,
                setSubmitting,
                setFieldError,
              }) => (
                <form noValidate className="" onSubmit={handleSubmit}>
                  <Stack spacing={2} sx={{ mr: 2 }}>
                    {fields.map((field) => (
                      <React.Fragment key={field.props.name}>
                        <field.component
                          disabled
                          label={field.label}
                          {...field.props}
                        ></field.component>
                      </React.Fragment>
                    ))}
                  </Stack>
                </form>
              )}
            </Formik>
          </Box>
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={locale} onChange={handleChange}>
            {enabledLanguages.map((enabledLanguage: any) => (
              <Tab
                label={enabledLanguage.language}
                value={enabledLanguage.locale}
                id={"locale-button-" + enabledLanguage.locale}
                key={"locale-button-key-" + enabledLanguage.locale}
              />
            ))}
          </Tabs>
        </Box>

        {enabledLanguages.map((enabledLanguage: any) => {
          return (
            <Box
              sx={{ paddingTop: "4px" }}
              hidden={!(enabledLanguage.locale === locale)}
              key={"locale-form-key-" + enabledLanguage.locale}
            >
              <Formik
                initialValues={translationItemDefaultValues}
                enableReinitialize
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  setSubmitting(false);
                }}
              >
                {({ handleSubmit, setSubmitting, isSubmitting, values }) => (
                  <form noValidate className="" onSubmit={handleSubmit}>
                    <Stack spacing={2} sx={{ mr: 2 }}>
                      {fields.map((field) => (
                        <React.Fragment key={field.props.name}>
                          <field.component
                            label={field.label}
                            {...field.props}
                          ></field.component>
                        </React.Fragment>
                      ))}
                      <Stack
                        direction={"row"}
                        spacing={2}
                        justifyContent={"space-between"}
                      >
                        <LoadingButton
                          variant="contained"
                          loading={isSubmitting}
                          onClick={() =>
                            handleSubmitTranslations(
                              values,
                              setSubmitting,
                              "nextItem"
                            )
                          }
                        >
                          {t.localization.saveAndNextItem}
                        </LoadingButton>
                        <LoadingButton
                          variant="contained"
                          loading={isSubmitting}
                          onClick={() =>
                            handleSubmitTranslations(
                              values,
                              setSubmitting,
                              "nextLanguage"
                            )
                          }
                        >
                          {t.localization.saveAndNextLanguage}
                        </LoadingButton>
                      </Stack>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Box>
          );
        })}
      </Box>
    </Stack>
  );
}
